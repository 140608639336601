import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  item: {},
  loading: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_SERVICE:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case types.SET_SERVICES:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case types.DELETE_SERVICE:
      return {
        ...state,
        items: state.items.filter((item) => item?.id !== action.payload.id),
        loading: false
      };
    default:
      return state;
  }
}
