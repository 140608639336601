import { useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Stack,
  Typography,
  Grid,
  FormLabel,
  Button,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import Loader from 'src/components/Loader';
import { singleCategory, updateCategory } from 'src/Redux/actions/blogCategoryActions'; // Actions

export default function EditBlogCategory() {
  const navigate = useNavigate();
  const { slug: id } = useParams();
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.blogCategories);
  const { loading, item: category } = pageState;

  useEffect(() => {
    dispatch(singleCategory(id));
  }, []);

  // Validation Schema
  const BlogCategorySchema = Yup.object().shape({
    name: Yup.string().required('Blog category name is required'),
    slug: Yup.string().required('Blog category slug is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        {loading && <Loader />}
        {!loading && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={category}
                validationSchema={BlogCategorySchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updateCategory(values, navigate, setSubmitting));
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  isSubmitting
                }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Edit Blog Category
                    </Typography>
                    <Stack spacing={3} mt={2}>
                      <TextField
                        type="text"
                        name="name"
                        label="Name"
                        error={!!errors.name}
                        helperText={touched.name && errors.name && errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        type="text"
                        name="slug"
                        label="Slug"
                        value={values.slug}
                        error={!!errors.name}
                        helperText={touched.name && errors.name && errors.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormLabel id="active">Status</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.active == 1}
                            onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                          />
                        }
                        label={values.active == 1 ? 'Active' : 'Inactive'}
                      />
                    </Stack>
                    <Grid container>
                      <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={5} sm={5} md={3} lg={2} ml={3} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/blog-categories"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
