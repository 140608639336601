import React, { useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sentenceCase } from 'change-case';
import { GBP } from 'src/utils/formatNumber';
import { getLabelColor } from '../Orders';
import withRole from 'src/components/HOC/withRole';
// material
import {
  Grid,
  Container,
  Stack,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
  // IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
//
import { singleOrder } from 'src/Redux/actions/ordersActions';
// components
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import Label from 'src/components/Label';
import OrderDetailActions from './OrderDetailActions';

const OrderDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageState = useSelector((state) => state.orders.orderDetail);
  const isLoading = useSelector((state) => state.orders.loading);
  // For User Role
  const userRole = props.user.user_role;
  // const [state, setState] = useState({});

  useEffect(() => {
    const fetchData = () => {
      dispatch(singleOrder({ order_id: id }));
    };
    fetchData();
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      // setState({});
      return type;
    };
  }, []);

  return (
    <Page title="Dashboard: Pages | Order Details">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={2} flexWrap={'wrap'}>
          <Typography variant="h4" gutterBottom>
            Order Detail
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/dashboard/orders"
            startIcon={<ArrowBackIcon />}
          >
            Back to orders
          </Button>
        </Stack>
        {isLoading && <Loader />}
        {!isLoading && pageState?.order_id && (
          <Grid container display="flex" alignItems="start" justifyContent="start" spacing={1}>
            <Grid item lg={6} md={6} xs={12} sm={12} mt={2}>
              <Box sx={{ boxShadow: 3, borderRadius: '16px' }}>
                <Card sx={{ maxWidth: '100%' }}>
                  <CardContent>
                    <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                      <strong>User Email:</strong> {pageState?.user_email}
                    </Typography>
                    <strong>Payment Status:</strong>
                    <Label
                      variant="ghost"
                      sx={{ ml: 1 }}
                      color={getLabelColor(pageState?.payment_status)}
                    >
                      {sentenceCase(
                        pageState?.payment_status ? pageState?.payment_status : 'unavailable'
                      )}
                    </Label>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Original Price: </strong>
                      {GBP.format(pageState.original_price)}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Amount Paid: </strong>
                      {GBP.format(pageState.paid_amount)}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Payment Risk Score: </strong>
                      {pageState.stripe_risk_score}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Promo Used: </strong>
                      {pageState.coupon_code ? ' Yes' : ' No'}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Promo Code: </strong>
                      {pageState.promo?.coupon_code}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Tracking Id: </strong>
                      {pageState.order_id}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Order Date: </strong>
                      {moment(pageState.date).format('MMMM Do, YYYY')}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Tracking Link: </strong>
                      <a
                        href={`${process.env.REACT_APP_SITE_URI}track-order/${id}`}
                        target="_blank"
                      >
                        Track Now
                      </a>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
        {!isLoading && pageState?.order_items?.length > 0 && (
          <Typography variant="h4" gutterBottom mt={3}>
            Order Items
          </Typography>
        )}
        <Grid container display="flex">
          {!isLoading &&
            pageState?.order_id &&
            pageState?.order_items &&
            pageState.order_items?.map((orderItem) => (
              <Grid key={orderItem.id} item lg={6} md={6} xs={12} sm={12} mt={2}>
                <Box sx={{ maxWidth: '98%', boxShadow: 3, borderRadius: '16px' }}>
                  <Card>
                    <CardContent>
                      <Typography
                        mt={1}
                        mb={2}
                        variant="body2"
                        sx={{ fontSize: 17, textTransform: 'capitalize' }}
                      >
                        <strong>Service Type:</strong> {orderItem.service_type}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Quantity:</strong> {orderItem.package_quantity}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>JAP Service ID:</strong> {orderItem.service_id}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Post Links:</strong>
                      </Typography>
                      <OrderItemLink orderItem={orderItem} orderId={pageState?.order_id} />
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Page>
  );
};
export default withRole(OrderDetails);

const OrderItemLink = ({ orderId, orderItem }) => (
  <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
    {orderItem?.item_posts &&
      orderItem?.item_posts?.length > 0 &&
      orderItem?.item_posts.map((itemLink, idx) => (
        <React.Fragment>
          <ListItem
            key={idx}
            alignItems="flex-start"
            secondaryAction={<OrderDetailActions orderId={orderId} itemPost={itemLink} />}
          >
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    JAP Order ID:
                  </Typography>
                  {` — ${itemLink?.post_api_order_id}`}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Quantity:
                  </Typography>
                  {` — ${itemLink?.quantity}`}
                  <br />
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Comments:
                  </Typography>
                  {` — ${itemLink?.comments}`}
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Link:
                  </Typography>
                  {` — ${itemLink?.post_id}`}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
  </List>
);
