import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Container, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import StripeSettingAction from './components/StripeSettingAction';
import Label from 'src/components/Label';
import { getSettings } from 'src/Redux/actions/stripeSettingActions';
import withRole from 'src/components/HOC/withRole';
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';

function StripeSetting(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripeSettings = useSelector((state) => state.stripeSetting.items);
  const loading = useSelector((state) => state.stripeSetting.loading);
  // For User Role
  const userRole = props.user.user_role;
  // const [state, setState] = useState({});

  // Get All Settings
  React.useEffect(() => {
    getStripeSettings();
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  const getStripeSettings = async () => {
    dispatch(getSettings());
  };

  // Stripe Table
  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 150 },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
      renderCell: (rowData) => <p style={{ textTransform: 'uppercase' }}>{rowData.row.currency}</p>
    },
    {
      field: 'stripePublishableKey',
      headerName: 'Public Key',
      width: 220
    },
    {
      field: 'stripeSecretKey',
      headerName: 'Secret Key',
      width: 220
    },
    {
      field: 'endpointSecret',
      headerName: 'Webhook Secret',
      width: 110,
      renderCell: (rowData) => (
        <p style={{ textTransform: 'uppercase' }}>{rowData.row.endpointSecret}</p>
      )
    },
    {
      field: 'active',
      headerName: 'Status',
      width: 110,
      renderCell: (rowData) => (
        <Label variant="ghost" sx={{ ml: 1 }} color={rowData.row.active == 1 ? 'success' : 'error'}>
          {rowData.row.active == 1 ? 'Active' : 'Inactive'}
        </Label>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 130,
      renderCell: (rowData) => <StripeSettingAction id={rowData.row.id} />
    }
  ];

  return (
    <Page title="Dashboard: Stripe Setting">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Stripe Settings
          </Typography>

          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/stripe-setting/add-stripe-setting"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Stripe Setting
          </Button> */}
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
            <Typography variant="h6" gutterBottom pt={2} pl={2}>
              Data Table of Stripe Setting
            </Typography>
            <DataGrid
              rows={stripeSettings}
              columns={columns}
              getRowId={(row) => [row?.id]}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              // checkboxSelection
              autoHeight={true}
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default withRole(StripeSetting);
