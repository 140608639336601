import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  item: {},
  loading: true,
  user: null,
  nonAuthLoading: true,
  userRoles: [],
  userRole: {}
};

// Users & Users Role Reducers
export default function (state = initialState, action) {
  switch (action.type) {
    // User Reducers
    case types.AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case types.AUTH_FAILED:
      return {
        ...state,
        loading: false
      };
    case types.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        user: null
      };
    // User Reducers
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        // loginSuccess: action.payload,
        loading: false
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        item: action.payload
      };
    // case types.SINGLE_USER_ATTEMPT:
    //   return {
    //     ...state,
    //     item: {},
    //     loading: false
    //   };
    case types.SINGLE_USER_SUCCESS:
      return {
        ...state,
        item: action.payload,
        nonAuthLoading: false
      };
    // case types.GET_ALL_USERS_ATTEMPT:
    //   return {
    //     ...state,
    //     items: [],
    //     loading: true
    //   };
    case types.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        nonAuthLoading: false
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        item: action.payload
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id)
      };
    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };

    // User Role Reducers
    // case types.CREATE_USER_ROLE_SUCCESS:
    //   return {
    //     ...state,
    //     userRoles: action.payload,
    //     loading: false
    //   };
    case types.SINGLE_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRole: action.payload,
        loading: false
      };
    case types.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRoles: action.payload,
        loading: false
      };
    // case types.UPDATE_USER_ROLE_SUCCESS:
    //   return {
    //     ...state,
    //     items: action.payload,
    //     loading: false
    //   };
    case types.DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        userRoles: state.userRoles.filter((item) => item.id !== action.payload.id)
      };
    default:
      return state;
  }
}
