import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
// material
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuPopover from 'src/components/MenuPopover';
//
import { logout } from 'src/Redux/actions/authActions';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: <HomeIcon />,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: <PersonIcon />,
    linkTo: '/dashboard/user-profile'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  // Single User
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.auth.loading);

  // Modal open / close
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Logout
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          src={user?.profile_pic ?? '/static/mock-images/avatars/avatar_default.jpg'}
          alt="photoURL"
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {isLoading ? (
          'Loading...'
        ) : (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {user?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>
        )}
        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box mr={1}>{option.icon}</Box>
            <Box>{option.label}</Box>
          </MenuItem>
        ))}

        <Divider sx={{ my: 1 }} />

        <MenuItem
          component={RouterLink}
          sx={{ typography: 'body2', py: 1, px: 2.5, mb: 1 }}
          onClick={handleLogout}
        >
          <Box mr={1}>
            <LogoutIcon />
          </Box>
          <Box>Logout</Box>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
