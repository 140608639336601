import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Typography, Button, Container } from '@mui/material';
import moment from 'moment';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import UserActionButton from './components/UserActionButton';
import withRole from 'src/components/HOC/withRole';
import Loader from 'src/components/Loader';
import { fetchUserList } from 'src/Redux/actions/authActions';

function AllUsers(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.auth);
  const users = state.items || [];
  const isLoading = state.nonAuthLoading;
  // For User Role
  const userRole = props.user.user_role;

  // Get All Users
  React.useEffect(() => {
    // get all users
    dispatch(fetchUserList());

    // Check User Role
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70, renderCell: (rowData) => rowData + 1 },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      renderCell: (rowData) => <p style={{ textTransform: 'capitalize' }}>{rowData.row.name}</p>
    },
    { field: 'email', headerName: 'Email', width: 250 },
    {
      field: 'user_role',
      headerName: 'User Role',
      width: 150,
      renderCell: (rowData) => (
        <>
          {rowData.row.user_role == '1' ? 'Admin' : rowData.row.user_role == '2' ? 'SEO' : 'User'}
        </>
      )
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      renderCell: (rowData) => moment(rowData.row.created_at).format('MMMM Do, YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 160,
      renderCell: (rowData) => <UserActionButton id={rowData.row.id} />
    }
  ];

  return (
    <Page title="Dashboard: Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            All Users
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/users/create-user"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add User
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" gutterBottom>
            Data Table of Users
          </Typography>
        </Stack>
        {isLoading && <Loader />}
        {!isLoading && (
          <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
            <DataGrid
              rows={users}
              getRowId={(row) => row.id}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
              // checkboxSelection
              autoHeight={true}
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
}

export default withRole(AllUsers);
