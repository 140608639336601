import { useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  CardContent
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RichTextEditor from 'src/components/RichTextEditor';
//
import Loader from 'src/components/Loader';
import { singleCategory, updateCategory } from 'src/Redux/actions/categoryActions';

export default function EditCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.categories.item);
  const isLoading = useSelector((state) => state.categories.loading);

  useEffect(() => {
    dispatch(singleCategory(id));
  }, []);

  const CategorySchema = Yup.object().shape({
    service_name: Yup.string().required('Service Name is required'),
    display_title: Yup.string().required('Page heading is required'),
    cate_id: Yup.string().required('Parent service is required'),
    slug: Yup.string().required('Category slug is required'),
    service_id: Yup.string().required('JAP Id is required')
  });

  return (
    <Grid container>
      <Grid lg={10} md={10} xs={10} sm={10} mx="auto">
        {isLoading && <Loader />}
        {!isLoading && pageState?.id && (
          <Card sx={{ minWidth: 275, px: 5, pt: 2 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={pageState}
                validationSchema={CategorySchema}
                onSubmit={(values, { setSubmitting }) => {
                  delete values.services;
                  dispatch(updateCategory(values, navigate, setSubmitting));
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting,
                  handleChange,
                  handleBlur
                }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(errors, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Update Package Category
                    </Typography>
                    <Stack spacing={3} mt={2}>
                      <TextField
                        type="text"
                        name="service_name"
                        label={'Category Name'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.service_name}
                        error={errors.service_name && touched.service_name}
                        helperText={
                          errors.service_name && touched.service_name && errors.service_name
                        }
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="display_title"
                        label={'Page Heading'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.display_title}
                        error={errors.display_title && touched.display_title}
                        helperText={
                          errors.display_title && touched.display_title && errors.display_title
                        }
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <FormControl fullWidth error={!!errors.cate_id}>
                        <InputLabel id="parent-service-id">Parent Service</InputLabel>
                        <Select
                          id="parent-id"
                          labelId="parent-service-id"
                          label="Parent Service"
                          value={values.cate_id}
                          name="cate_id"
                          disabled={isSubmitting}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Not Selected</MenuItem>
                          {pageState?.services?.map((item) => (
                            <MenuItem value={item.id} label={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        type="text"
                        name="slug"
                        label={'Category Slug'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.slug}
                        error={errors.slug && touched.slug}
                        helperText={errors.slug && touched.slug && errors.slug}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="service_id"
                        label={'JAP Id'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.service_id}
                        error={errors.service_id && touched.service_id}
                        helperText={errors.service_id && touched.service_id && errors.service_id}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="short_description"
                        label={'Short Description'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.short_description}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <FormLabel id="description">Long Description</FormLabel>
                      <RichTextEditor
                        setFieldValue={setFieldValue}
                        name="description"
                        value={values.description}
                      />
                      <TextField
                        type="text"
                        name="page_title"
                        label={'Meta Title'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.page_title}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="canonical_link"
                        label={'Canonical Link'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.canonical_link}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="meta_description"
                        label={'Meta Description'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.meta_description}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <FormControlLabel
                        sx={{ justifyContent: 'start' }}
                        control={
                          <Switch
                            checked={values.active == '1'}
                            onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                            disabled={isSubmitting}
                          />
                        }
                        label={values.active == '1' ? 'Active' : 'Disbaled'}
                        labelPlacement="start"
                      />
                    </Stack>
                    <Grid container>
                      <Grid xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/package-category"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
