import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
// material
import {
  Stack,
  Typography,
  Grid,
  FormLabel,
  Button,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { addBlogCategory } from 'src/Redux/actions/blogCategoryActions';
// ----------------------------------------------------------------------

export default function CreateBlogCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Validation Schema
  const BlogCategorySchema = Yup.object().shape({
    name: Yup.string().required('Blog category name is required'),
    slug: Yup.string().required('Blog category slug is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: '',
                slug: '',
                active: 1
              }}
              validationSchema={BlogCategorySchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(addBlogCategory(values, navigate, setSubmitting));
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting
              }) => (
                <Form autoComplete="off">
                  {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                  <Typography variant="h4" gutterBottom>
                    Add Blog Category
                  </Typography>
                  <Stack spacing={3} mt={2}>
                    <TextField
                      type="text"
                      name="name"
                      label="Name"
                      error={!!errors.name}
                      helperText={touched.name && errors.name && errors.name}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      name="slug"
                      label="Slug"
                      error={!!errors.slug}
                      helperText={touched.slug && errors.slug && errors.slug}
                      value={values.slug}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormLabel id="active">Status</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.active == 1}
                          onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                        />
                      }
                      label={values.active == 1 ? 'Active' : 'Inactive'}
                    />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={3} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        disabled={isSubmitting}
                        to="/dashboard/blog-categories"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
