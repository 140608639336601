import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  FormLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import RichTextEditor from 'src/components/RichTextEditor';
import MuiFormikTextField from 'src/components/UI/MuiFormikField';
import { getServicesCategories } from 'src/api/api';
//
import { addPackage } from 'src/Redux/actions/packageActions';

export default function AddPackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [personName, setPersonName] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get All Categories
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const resp = await getServicesCategories();
      if (resp.status === 200) {
        const { serviceCategories } = resp.data;
        setCategories(serviceCategories);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Validation Schema for From
  const PackageSchema = Yup.object().shape({
    packageTitle: Yup.string().required('Package Title is required'),
    package_slug: Yup.string().required('Package Slug is required'),
    packageQty: Yup.string().required('Quantity is required'),
    packagePrice: Yup.string().required('Price is required'),
    sub_category_id: Yup.string().required('Package Category is required'),
    priceUnit: Yup.string().required('Price Unit is required'),
    serviceType: Yup.string().required('Service Type is required')
  });

  return (
    <Grid container>
      <Grid item lg={10} md={10} xs={10} sm={10} mx="auto">
        <Card sx={{ minWidth: 275, p: 4 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                packageTitle: '',
                sub_category_id: '',
                package_slug: '',
                packageQty: '',
                packagePrice: '',
                priceUnit: '',
                delieveryTime: '',
                serviceType: '',
                active: 1,
                // pckgDescription: '',
                metaTitle: '',
                metaDescription: '',
                canonicalLink: ''
              }}
              validationSchema={PackageSchema}
              onSubmit={(values, { setSubmitting }) => {
                // console.log('values', values);
                dispatch(addPackage(values, navigate, setSubmitting));
              }}
            >
              {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                <Form autoComplete="off">
                  {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                  <Typography variant="h4" gutterBottom>
                    Add New Package
                  </Typography>
                  <Stack spacing={1} mt={3}>
                    <FormLabel id="packageTitle">Package Title</FormLabel>
                    <Field
                      type="text"
                      id="packageTitle"
                      name="packageTitle"
                      component={MuiFormikTextField}
                      error={!!errors.packageTitle}
                      helperText={
                        errors.packageTitle && touched.packageTitle && errors.packageTitle
                      }
                      placeholder="Enter Package Title"
                    />
                    <FormLabel id="sub_category_id">Package Category</FormLabel>
                    <Field
                      select
                      id="sub_category_id"
                      name="sub_category_id"
                      component={MuiFormikTextField}
                      error={!!errors.sub_category_id}
                      helperText={
                        errors.sub_category_id && touched.sub_category_id && errors.sub_category_id
                      }
                      label="Choose a category"
                    >
                      <MenuItem value="">Not Selected</MenuItem>
                      {categories?.map((item) => (
                        <MenuItem value={item.id} label={item.service_name} key={item.id}>
                          {item.service_name}
                        </MenuItem>
                      ))}
                    </Field>
                    <FormLabel id="package_slug">Package Slug</FormLabel>
                    <Field
                      type="text"
                      id="package_slug"
                      name="package_slug"
                      component={MuiFormikTextField}
                      error={!!errors.package_slug}
                      helperText={
                        errors.package_slug && touched.package_slug && errors.package_slug
                      }
                      placeholder="Enter Package Slug"
                    />
                    <FormLabel id="packageQty">Quantity</FormLabel>
                    <Field
                      type="text"
                      id="packageQty"
                      name="packageQty"
                      component={MuiFormikTextField}
                      error={!!errors.packageQty}
                      helperText={errors.packageQty && touched.packageQty && errors.packageQty}
                      placeholder="Enter Package Quantity"
                    />
                    <FormLabel id="packagePrice">Price</FormLabel>
                    <Field
                      type="text"
                      id="packagePrice"
                      name="packagePrice"
                      component={MuiFormikTextField}
                      error={!!errors.packagePrice}
                      helperText={
                        errors.packagePrice && touched.packagePrice && errors.packagePrice
                      }
                      placeholder="Enter Package Price"
                    />
                    <FormLabel id="radios">Price Unit</FormLabel>
                    <Field
                      name="priceUnit"
                      select
                      component={MuiFormikTextField}
                      label="Select a currency"
                      error={!!errors.priceUnit}
                      helperText={errors.priceUnit && touched.priceUnit && errors.priceUnit}
                    >
                      <MenuItem value="" label="Currency">
                        Currency
                      </MenuItem>
                      <MenuItem value="£" label="£">
                        £
                      </MenuItem>
                      <MenuItem value="$" label="$">
                        $
                      </MenuItem>
                    </Field>
                    <FormLabel id="delieveryTime">Delivery Time</FormLabel>
                    {/* <Typography variant="caption">
                      Features e.g. Delivery Time (One feature per line)
                    </Typography> */}
                    <Field
                      type="text"
                      id="delieveryTime"
                      name="delieveryTime"
                      component={MuiFormikTextField}
                      error={!!errors.delieveryTime}
                      helperText={
                        errors.delieveryTime && touched.delieveryTime && errors.delieveryTime
                      }
                      placeholder="Delivery Time"
                    />
                    {/* <RichTextEditor
                      setFieldValue={setFieldValue}
                      name="delieveryTime"
                      value={values.delieveryTime}
                    /> */}
                    <FormLabel id="serviceType">Service Type</FormLabel>
                    <Field
                      name="serviceType"
                      select
                      component={MuiFormikTextField}
                      error={!!errors.serviceType}
                      helperText={errors.serviceType && touched.serviceType && errors.serviceType}
                      label="Select Service Type"
                    >
                      <MenuItem value="">Selected a value</MenuItem>
                      {categories?.map((item) => (
                        <MenuItem value={item.slug} label={item.service_name} key={item.id}>
                          {item.service_name}
                        </MenuItem>
                      ))}
                    </Field>
                    {/* <FormLabel id="pckgDescription">Pacakge Description</FormLabel>
                    <RichTextEditor
                      setFieldValue={setFieldValue}
                      name="pckgDescription"
                      value={values.pckgDescription}
                    />
                    {errors.pckgDescription && touched.pckgDescription ? (
                      <div className="error-color">{errors.pckgDescription}</div>
                    ) : null} */}
                    <FormLabel id="metaTitle">Meta Title</FormLabel>
                    <Field
                      type="text"
                      id="metaTitle"
                      name="metaTitle"
                      component={MuiFormikTextField}
                      placeholder="Meta Title"
                    />
                    <FormLabel id="metaDescription">Meta Description</FormLabel>
                    <RichTextEditor
                      setFieldValue={setFieldValue}
                      name="metaDescription"
                      value={values.metaDescription}
                    />
                    <FormLabel id="canonicalLink">Canonical Link</FormLabel>
                    <Field
                      type="text"
                      id="canonicalLink"
                      name="canonicalLink"
                      component={MuiFormikTextField}
                      placeholder="Canonical Link"
                    />
                    <FormControlLabel
                      disabled={isSubmitting}
                      sx={{ justifyContent: 'start' }}
                      control={
                        <Switch
                          checked={values.active == '1'}
                          onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                        />
                      }
                      label={values.active == '1' ? 'Active' : 'Disbaled'}
                      labelPlacement="start"
                    />
                  </Stack>
                  <Grid container>
                    <Grid xs={4} sm={4} md={3} lg={2} mt={3} item>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3} item>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        disabled={isSubmitting}
                        to="/dashboard/packages"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
