import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-mui';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  FormLabel,
  MenuItem,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { singlePackage, updatePackage } from 'src/Redux/actions/packageActions';
import MuiFormikTextField from 'src/components/UI/MuiFormikField';
import RichTextEditor from 'src/components/RichTextEditor';
import Loader from 'src/components/Loader';
//

export default function EditPackage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Get All Packages
  const pageState = useSelector((state) => state.packages);

  useEffect(() => {
    dispatch(singlePackage(id));
  }, []);

  // Validation Schema for From
  const PackageSchema = Yup.object().shape({
    packageTitle: Yup.string().required('Package Title is required'),
    package_slug: Yup.string().required('Package Slug is required'),
    packageQty: Yup.string().required('Quantity is required'),
    packagePrice: Yup.string().required('Price is required'),
    sub_category_id: Yup.string().required('Package Category is required'),
    priceUnit: Yup.string().required('Price Unit is required'),
    delieveryTime: Yup.string(),
    serviceType: Yup.string().required('Service Type is required')
  });

  const { categories, ...pkg } = pageState.item || {};

  return (
    <Grid container>
      <Grid item lg={10} md={10} xs={10} sm={10} mx="auto">
        {pageState.loading && <Loader />}
        {!pageState.loading && pkg?.packageTitle && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent sx={{ px: 5 }}>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: id,
                  packageTitle: pkg.packageTitle ? pkg.packageTitle : '',
                  sub_category_id: pkg.sub_category_id ? pkg.sub_category_id : '',
                  package_slug: pkg.package_slug ? pkg.package_slug : '',
                  packageQty: pkg.packageQty ? pkg.packageQty : '',
                  packagePrice: pkg.packagePrice ? pkg.packagePrice : '',
                  priceUnit: pkg.priceUnit ? pkg.priceUnit : '',
                  delieveryTime: pkg.delieveryTime ? pkg.delieveryTime : '',
                  serviceType: pkg.serviceType ? pkg.serviceType : '',
                  service_id: pkg.service_id ? pkg.service_id : '',
                  // pckgDescription: pkg.pckgDescription ? pkg.pckgDescription : '',
                  metaTitle: pkg.metaTitle ? pkg.metaTitle : '',
                  metaDescription: pkg.metaDescription ? pkg.metaDescription : '',
                  canonicalLink: pkg.canonicalLink ? pkg.canonicalLink : '',
                  active: pkg.active ? pkg.active : 1
                }}
                validationSchema={PackageSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updatePackage(values, navigate, setSubmitting));
                }}
              >
                {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(errors, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Edit Package
                    </Typography>
                    <Stack spacing={1} mt={3}>
                      <FormLabel id="packageTitle">Package Title</FormLabel>
                      <Field
                        type="text"
                        id="packageTitle"
                        name="packageTitle"
                        component={MuiFormikTextField}
                        error={!!errors.packageTitle}
                        helperText={
                          errors.packageTitle && touched.packageTitle && errors.packageTitle
                        }
                        disabled={isSubmitting}
                        placeholder="Enter Package Title"
                      />
                      <FormLabel id="sub_category_id">Package Category</FormLabel>
                      <Field
                        select
                        id="sub_category_id"
                        name="sub_category_id"
                        component={MuiFormikTextField}
                        error={!!errors.sub_category_id}
                        helperText={
                          errors.sub_category_id &&
                          touched.sub_category_id &&
                          errors.sub_category_id
                        }
                        disabled={isSubmitting}
                        label="Choose a category"
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {categories?.map((item) => (
                          <MenuItem value={item.id} label={item.service_name} key={item.id}>
                            {item.service_name}
                          </MenuItem>
                        ))}
                      </Field>
                      <FormLabel id="package_slug">Package Slug</FormLabel>
                      <Field
                        type="text"
                        id="package_slug"
                        name="package_slug"
                        component={MuiFormikTextField}
                        error={!!errors.package_slug}
                        helperText={
                          errors.package_slug && touched.package_slug && errors.package_slug
                        }
                        disabled={isSubmitting}
                        placeholder="Enter Package Slug"
                      />
                      <FormLabel id="packageQty">Quantity</FormLabel>
                      <Field
                        type="text"
                        id="packageQty"
                        name="packageQty"
                        component={MuiFormikTextField}
                        error={!!errors.packageQty}
                        helperText={errors.packageQty && touched.packageQty && errors.packageQty}
                        disabled={isSubmitting}
                        placeholder="Enter Package Quantity"
                      />
                      <FormLabel id="packagePrice">Price</FormLabel>
                      <Field
                        type="text"
                        id="packagePrice"
                        name="packagePrice"
                        component={MuiFormikTextField}
                        error={!!errors.packagePrice}
                        helperText={
                          errors.packagePrice && touched.packagePrice && errors.packagePrice
                        }
                        disabled={isSubmitting}
                        placeholder="Enter Package Price"
                      />
                      <FormLabel id="radios">Price Unit</FormLabel>
                      <Field
                        name="priceUnit"
                        select
                        component={TextField}
                        label="Select a currency"
                        error={!!errors.priceUnit}
                        helperText={errors.priceUnit && touched.priceUnit && errors.priceUnit}
                        disabled={isSubmitting}
                      >
                        <MenuItem value="" label="Currency">
                          Currency
                        </MenuItem>
                        <MenuItem value="£" label="£">
                          £
                        </MenuItem>
                        <MenuItem value="$" label="$">
                          $
                        </MenuItem>
                      </Field>
                      <FormLabel id="delieveryTime">Delivery Time</FormLabel>
                      {/* <Typography variant="caption">
                      Features e.g. Delivery Time (One feature per line)
                    </Typography> */}
                      <Field
                        type="text"
                        id="delieveryTime"
                        name="delieveryTime"
                        component={MuiFormikTextField}
                        error={!!errors.delieveryTime}
                        helperText={
                          errors.delieveryTime && touched.delieveryTime && errors.delieveryTime
                        }
                        disabled={isSubmitting}
                        placeholder="Delivery Time"
                      />
                      {/* <RichTextEditor
                      setFieldValue={setFieldValue}
                      name="delieveryTime"
                      value={values.delieveryTime}
                    /> */}
                      <FormLabel id="serviceType">Service Type</FormLabel>
                      <Field
                        name="serviceType"
                        select
                        component={MuiFormikTextField}
                        error={!!errors.serviceType}
                        helperText={errors.serviceType && touched.serviceType && errors.serviceType}
                        disabled={isSubmitting}
                        label="Select Service Type"
                      >
                        <MenuItem value="">Selected a value</MenuItem>
                        {categories?.map((item) => (
                          <MenuItem value={item.slug} label={item.service_name} key={item._id}>
                            {item.service_name}
                          </MenuItem>
                        ))}
                      </Field>
                      {/* <FormLabel id="pckgDescription">Pacakge Description</FormLabel>
                    <RichTextEditor
                      setFieldValue={setFieldValue}
                      name="pckgDescription"
                      value={values.pckgDescription}
                    />
                    {errors.pckgDescription && touched.pckgDescription ? (
                      <div className="error-color">{errors.pckgDescription}</div>
                    ) : null} */}
                      <FormLabel id="metaTitle">Meta Title</FormLabel>
                      <Field
                        type="text"
                        id="metaTitle"
                        name="metaTitle"
                        component={MuiFormikTextField}
                        disabled={isSubmitting}
                        placeholder="Meta Title"
                      />
                      <FormLabel id="metaDescription">Meta Description</FormLabel>
                      <RichTextEditor
                        setFieldValue={setFieldValue}
                        name="metaDescription"
                        value={values.metaDescription}
                      />
                      <FormLabel id="canonicalLink">Canonical Link</FormLabel>
                      <Field
                        type="text"
                        id="canonicalLink"
                        name="canonicalLink"
                        component={MuiFormikTextField}
                        disabled={isSubmitting}
                        placeholder="Canonical Link"
                      />
                      <FormControlLabel
                        disabled={isSubmitting}
                        sx={{ justifyContent: 'start' }}
                        control={
                          <Switch
                            checked={values.active == '1'}
                            onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                            disabled={isSubmitting}
                          />
                        }
                        label={values.active == '1' ? 'Active' : 'Disbaled'}
                        labelPlacement="start"
                      />
                    </Stack>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/packages"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
