import * as types from '../constants/actionTypes';

const initialState = {
  items: [],
  item: {},
  loading: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        categories: action.payload,
        loading: false
      };
    case types.SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };
    case types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        items: action.payload,
        categories: action.payload,
        loading: false
      };
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id)
      };
    default:
      return state;
  }
}
