import { toast } from 'react-toastify';
import axios from 'axios';
import * as types from '../constants/actionTypes';
import {
  getAllBlogs,
  createBlog,
  getBlogById,
  updateBlog as UpdateBlog,
  deleteBlog as DeleteBlog
} from 'src/api/api';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Create Blog
export const addBlog = (data, navigate) => async () => {
  try {
    const resp = await createBlog(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Blog Added Successsully');
    }
    setTimeout(() => {
      navigate('/dashboard/blogs');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
  }
};

// Get All Blogs
export const allActiveBlogs = () => async (dispatch) => {
  try {
    const resp = await getAllBlogs();
    if (resp.status === 200) {
      const { blogs } = resp.data;
      dispatch({
        type: types.ALL_ACTIVE_BLOGS_SUCCESS,
        payload: blogs
      });
    }
  } catch (err) {
    dispatch({ type: types.ALL_ACTIVE_BLOGS_FAIL, payload: [] });
  }
};

// Get Single Blog
export const singleBlog = (id) => async (dispatch) => {
  try {
    const resp = await getBlogById(id);
    if (resp.status === 200) {
      const { blog = {}, categories = [] } = resp.data;
      dispatch({
        type: types.SINGLE_BLOG_SUCCESS,
        payload: { categories, ...blog }
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({ type: types.SINGLE_BLOG_SUCCESS, payload: {} });
  }
};

// Update Blog
export const updateBlog = (id, data, navigate, setLoading) => async () => {
  // console.log('🚀 ~ file: blogsActions.js:64 ~ updateBlog ~ data:', data);
  try {
    const resp = await UpdateBlog(id, data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'Blog updated successsully');
    }
    setTimeout(() => {
      navigate('/dashboard/blogs');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Delete Blog
export const deleteBlog = (data, handleClose) => async (dispatch) => {
  try {
    const resp = await DeleteBlog(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data?.message : 'Blog Deleted Successsully');
      dispatch({ type: types.DELETE_BLOG_SUCCESS, payload: { id: data.id } });
      handleClose();
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    handleClose();
  }
};
