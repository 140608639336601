import * as types from '../constants/actionTypes';
import { getOrderRefunds } from 'src/api/api';

// Get All Orders
export const orderRefunds = (reqData) => async (dispatch) => {
  try {
    const resp = await getOrderRefunds(reqData);
    if (resp.status === 200) {
      dispatch({
        type: types.SET_ORDER_REFUND,
        payload: resp.data.refunds
      });
    }
  } catch (err) {
    dispatch({
      type: types.SET_ORDER_REFUND,
      payload: {}
    });
  }
};

export const resetRefunds = () => async (dispatch) => {
  dispatch({
    type: types.SET_ORDER_REFUND,
    payload: {}
  });
};

// Get Single Order
// export const singleOrder = (reqData) => {
//   return async (dispatch) => {
//     try {
//       const resp = await getOrderDetails(reqData);
//       if (resp.status === 200) {
//         dispatch({
//           type: types.SINGLE_ORDER_SUCCESS,
//           payload: resp.data.order
//         });
//       }
//     } catch (err) {}
//   };
// };
