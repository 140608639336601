import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
// import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import './assets/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import rootReducer from './Redux/reducers';
// ----------------------------------------------------------------------
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export default function App() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        {/* <BaseOptionChartStyle /> */}
        <ToastContainer autoClose={3000} />
        <Router />
      </ThemeConfig>
    </Provider>
  );
}
