import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import {
  getPackageList,
  addPackage as createPackage,
  getPackage,
  udpatePackage,
  deletePackage as removePackage
} from 'src/api/api';

// Add Package
export const addPackage = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createPackage(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Package Added Successsully');
      setTimeout(() => {
        navigate('/dashboard/packages');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Get Single Package
export const singlePackage = (id) => async (dispatch) => {
  try {
    const resp = await getPackage(id);
    if (resp.status === 200) {
      const { package: pkg = {}, categories = [] } = resp.data;
      dispatch({
        type: types.SINGLE_PACKAGE_SUCCESS,
        payload: { ...pkg, categories } || {}
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({
      type: types.SINGLE_PACKAGE_SUCCESS,
      payload: {}
    });
  }
};

// Get All Categories
export const getAllPackages = () => async (dispatch) => {
  try {
    const resp = await getPackageList();
    if (resp.status === 200) {
      const { packages } = resp.data;
      dispatch({
        type: types.GET_PACKAGES_SUCCESS,
        payload: packages
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_PACKAGES_SUCCESS,
      payload: []
    });
  }
};

// Upadate Package
export const updatePackage = (data, navigate, setLoading) => async () => {
  try {
    const resp = await udpatePackage(data.id, data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data?.message : 'Package Updated Successsully');
      setTimeout(() => {
        navigate('/dashboard/packages');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Delete Package
export const deletePackage = (data, setModalOpen) => async (dispatch) => {
  try {
    const resp = await removePackage(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'Package deleted successsully');
      dispatch({ type: types.DELETE_PACAKGE_SUCCESS, payload: { id: data.id } });
    }
    setModalOpen(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
  }
};
