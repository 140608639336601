import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_LOCAL
    : process.env.REACT_APP_API_LIVE;

const controller = new AbortController();

export const API = axios.create({
  baseURL,
  // headers: { 'x-access-token': token }
  withCredentials: true
});

// Auth
export const isAuth = () => {
  const url = 'auth/is_auth';
  return sendRequest(url, 'get', null);
};

export const loginUser = (reqData = {}) => {
  const url = 'auth/login';
  return sendRequest(url, 'post', reqData);
};

export const logout = () => {
  const url = 'auth/logout';
  return sendRequest(url, 'post', null);
};

// blogs
export const getAllBlogs = () => {
  const url = 'blogs_list';
  return sendRequest(url, 'get', null);
};

export const createBlog = (reqData) => {
  const url = 'add_blog';
  return sendRequest(url, 'post', reqData);
};

export const getBlogById = (id) => {
  const url = `edit_blog/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateBlog = (id, reqData) => {
  const url = `edit_blog/${id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteBlog = (reqData) => {
  const url = `delete_blog`;
  return sendRequest(url, 'post', reqData);
};
// blogs ends

// blog categories
export const getBlogCategories = () => {
  const url = 'blog-categories';
  return sendRequest(url, 'get', null);
};

export const createBlogCategory = (reqData) => {
  const url = 'create-blog-category';
  return sendRequest(url, 'post', reqData);
};

export const getBlogCategory = (id) => {
  const url = `edit-blog-category/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateBlogCategory = (reqData) => {
  const url = `edit-blog-category/${reqData.id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteBlogCategory = (reqData) => {
  const url = `delete-category-blog`;
  return sendRequest(url, 'post', reqData);
};
// blogs categories end

// users
export const getUserList = () => {
  const url = 'users';
  return sendRequest(url, 'get', null);
};

export const createUser = (reqData) => {
  const url = 'user_create';
  return sendRequest(url, 'post', reqData);
};

export const getUserById = (id) => {
  const url = `user_edit/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateUserInfo = (id, reqData) => {
  const url = `user_edit/${id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteUserInfo = (reqData) => {
  const url = `user_delete`;
  return sendRequest(url, 'post', reqData);
};

export const updateProfileInfo = (reqData) => {
  const url = `update-profile`;
  return sendRequest(url, 'post', reqData);
};
// user end

// user roles
export const getUserRoles = () => {
  const url = `user_roles`;
  return sendRequest(url, 'get', null);
};

export const createUserRole = (reqData) => {
  const url = `create_user_role`;
  return sendRequest(url, 'post', reqData);
};

export const getUserRole = (id) => {
  const url = `edit_user_roles/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateUserRoles = (reqData) => {
  const url = `edit_user_roles/${reqData.id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteUserRoles = (reqData) => {
  const url = `delete_user_role`;
  return sendRequest(url, 'post', reqData);
};
// user roles end here

// orders
export const getAllOrders = () => {
  const url = 'orders';
  return sendRequest(url, 'get', null);
};

export const getOrderDetails = (reqData) => {
  const url = 'order_detail';
  return sendRequest(url, 'post', reqData);
};

export const placeJapCartOrder = (reqData) => {
  const url = 'order_request';
  return sendRequest(url, 'post', reqData);
};
export const placeJapItemOrder = (reqData) => {
  const url = 'order_item_request';
  return sendRequest(url, 'post', reqData);
};
export const placeJapItemPostOrder = (reqData) => {
  const url = 'order_item_post_request';
  return sendRequest(url, 'post', reqData);
};

export const updateOrderPostLinkData = (reqData) => {
  const url = 'update_order_post_link';
  return sendRequest(url, 'post', reqData);
};

export const createStripeRefund = (reqData) => {
  const url = 'create_stripe_refund';
  return sendRequest(url, 'post', reqData);
};
//
// Refunds
export const getOrderRefunds = (reqData) => {
  const url = 'get-order-refunds';
  return sendRequest(url, 'post', reqData);
};

// services, categories & packages
export const getServices = () => {
  const url = 'service_category';
  return sendRequest(url, 'get', null);
};

export const createService = (reqData) => {
  const url = 'create_service';
  return sendRequest(url, 'post', reqData);
};

export const getService = (id) => {
  const url = `service_category_edit/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateService = (id, reqData) => {
  const url = `service_category_edit/${id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteService = (reqData) => {
  const url = `delete_service_category`;
  return sendRequest(url, 'post', reqData);
};
// services end

// services categories
export const getServicesCategories = () => {
  const url = 'package-categories';
  return sendRequest(url, 'get', null);
};

export const createServiceCategory = (reqData) => {
  const url = 'create-package-category';
  return sendRequest(url, 'post', reqData);
};

export const getServiceCategory = (id) => {
  const url = `edit-package-category/${id}`;
  return sendRequest(url, 'get', null);
};

export const updateServiceCategory = (id, reqData) => {
  const url = `edit-package-category/${id}`;
  return sendRequest(url, 'post', reqData);
};

export const deleteServiceCategory = (reqData) => {
  const url = `delete-package-category`;
  return sendRequest(url, 'post', reqData);
};
// service categories end

// packages
export const getPackageList = () => {
  const url = 'packages_list';
  return sendRequest(url, 'get', null);
};

export const addPackage = (reqData) => {
  const url = 'create_package';
  return sendRequest(url, 'post', reqData);
};

export const getPackage = (id) => {
  const url = `package_edit/${id}`;
  return sendRequest(url, 'get', null);
};

export const udpatePackage = (id, reqData) => {
  const url = `package_edit/${id}`;
  return sendRequest(url, 'post', reqData);
};

export const deletePackage = (reqData) => {
  const url = `delete_package`;
  return sendRequest(url, 'post', reqData);
};
// packages end

// stripe settings
export const addStripeSettings = (reqData) => {
  const url = 'add-settings';
  return sendRequest(url, 'post', reqData);
};

export const getAllStripeSettings = () => {
  const url = 'stripe-settings';
  return sendRequest(url, 'get', null);
};

export const getStripeSettings = (reqData) => {
  const url = 'get-stripe-settings';
  return sendRequest(url, 'post', reqData);
};

export const updateStripeSettings = (reqData) => {
  const url = 'edit-settings';
  return sendRequest(url, 'post', reqData);
};

export const deleteStripeSettings = (reqData) => {
  const url = 'delete-settings';
  return sendRequest(url, 'post', reqData);
};
//

// users & user roles
export const updateUser = (reqData = {}) => {
  const url = 'admin/update_profile';
  return sendRequest(url, 'post', reqData);
};
export const changePassword = (reqData = {}) => {
  const url = 'admin/change_password';
  return sendRequest(url, 'post', reqData);
};

const sendRequest = async (url, method = 'get', data = {}) => {
  try {
    const resp = await API({
      url: url.endsWith('/') ? url : `${url}/`,
      method,
      data,
      signal: controller.signal
    });
    // controller.abort();
    return resp;
  } catch (error) {
    if (error.response) {
      console.log(error.response.status);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    // controller.abort();
    throw error;
  }
};
