import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  FormLabel,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//
import { updateProfile } from 'src/Redux/actions/authActions';

export default function EditUserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // Get Single User
  const pageState = useSelector((state) => state.auth.user);

  // Validation Schema for From
  const EidtUserSchema = Yup.object().shape({
    name: Yup.string().required('User Name is required'),
    // email: Yup.string().email('Must be a valid email').required('User Email is required'),
    password: Yup.string().min(8, 'Password is too short should be at least 8 characters'),
    confirmPassword: Yup.string()
      .min(8, 'Password is too short should be at least 8 characters')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: pageState?.name ? pageState?.name : '',
                email: pageState?.email ? pageState?.email : '',
                password: '',
                confirmPassword: ''
              }}
              validationSchema={EidtUserSchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(updateProfile(values, navigate, setSubmitting));
              }}
            >
              {({ errors, touched, isSubmitting, values, handleChange, handleBlur }) => (
                <Form autoComplete="off">
                  {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                  <Typography variant="h4" gutterBottom>
                    Edit Profle
                  </Typography>
                  <Typography variant="body" gutterBottom>
                    Note: Changes in profile will be reflected on next login.
                  </Typography>
                  <Stack spacing={3} mt={2}>
                    <Box>
                      <FormLabel id="name">Name</FormLabel>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="input-style"
                        placeholder="User Name"
                      />
                      {errors.name && touched.name && (
                        <div className="error-color">{errors.name}</div>
                      )}
                    </Box>
                    <Box>
                      <Tooltip title="Cannot Update Email">
                        <FormLabel id="email">Email</FormLabel>
                      </Tooltip>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="input-style"
                        placeholder="Enter User Email"
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="error-color">{errors.email}</div>
                      )}
                    </Box>
                    <Box>
                      <InputLabel htmlFor="profile-password">Password</InputLabel>
                      <OutlinedInput
                        name="password"
                        fullWidth
                        id="profile-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.password && touched.password && (
                        <div className="error-color">{errors.password}</div>
                      )}
                    </Box>
                    <Box>
                      <InputLabel htmlFor="confirmPassword">Password</InputLabel>
                      <OutlinedInput
                        name="confirmPassword"
                        fullWidth
                        id="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="error-color">{errors.confirmPassword}</div>
                      )}
                    </Box>
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        disabled={isSubmitting}
                        to="/dashboard/user-profile"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
