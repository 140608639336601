import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo() {
  return (
    <RouterLink to="/">
      <img src="/static/logo-sb.png" width={150} height={50} alt="Social Booster" />
    </RouterLink>
  );
}
