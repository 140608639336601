import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import {
  getServices,
  createService,
  getService,
  updateService as updatePkgService,
  deleteService as deletePkgService
} from 'src/api/api';

// Add Service
export const addService = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createService(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Service added successfully');
      setTimeout(() => {
        navigate('/dashboard/services');
      }, 1500);
    }
    setLoading(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Get Single Service
export const singleService = (id) => async (dispatch) => {
  try {
    const resp = await getService(id);
    if (resp.status === 200) {
      dispatch({
        type: types.SET_SERVICE,
        payload: resp.data.category
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({
      type: types.SET_SERVICE,
      payload: {}
    });
  }
};

// Get All Services
export const getAllServices = () => async (dispatch) => {
  try {
    const resp = await getServices();
    if (resp.status === 200) {
      dispatch({
        type: types.SET_SERVICES,
        payload: resp.data.categories
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({
      type: types.SET_SERVICES,
      payload: []
    });
  }
};

// Upadate Service
export const updateService = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updatePkgService(data.id, data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data?.message : 'Service Updated Successfully');
      setTimeout(() => {
        navigate('/dashboard/services');
      }, 1500);
    }
    setLoading(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Delete Service
export const deleteService = (data, setLoading, setModalOpen) => async (dispatch) => {
  try {
    const resp = await deletePkgService(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'Service deleted successfully');
      dispatch({ type: types.DELETE_SERVICE, payload: { id: data.id } });
    }
    setLoading(false);
    setModalOpen(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};
