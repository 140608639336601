import React, { useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
import { sentenceCase } from 'change-case';
import { GBP } from 'src/utils/formatNumber';
import { getLabelColor } from 'src/pages/Orders/Orders';
import withRole from 'src/components/HOC/withRole';
// material
import { Grid, Container, Stack, Typography, Button, Box, Card, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//
import { orderRefunds, resetRefunds } from 'src/Redux/actions/refundActions';
// components
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import Label from 'src/components/Label';

const OrderRefunds = (props) => {
  const { trxId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refundData = useSelector((state) => state.refunds.refundData);
  const isLoading = useSelector((state) => state.refunds.loading);
  // For User Role
  const userRole = props.user.user_role;
  // const [state, setState] = useState({});

  useEffect(() => {
    const fetchData = () => {
      dispatch(orderRefunds({ trxId }));
    };
    fetchData();
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      dispatch(resetRefunds());
      return type;
    };
  }, []);

  return (
    <Page title="Dashboard: Pages | Order Refunds">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={2} flexWrap={'wrap'}>
          <Typography variant="h4" gutterBottom>
            Order Refund Details
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/dashboard/orders"
            startIcon={<ArrowBackIcon />}
          >
            Back to orders
          </Button>
        </Stack>
        {isLoading && <Loader />}
        {!isLoading && refundData?.trxId ? (
          <Grid container display="flex" alignItems="start" justifyContent="start" spacing={1}>
            <Grid item lg={6} md={6} xs={12} sm={12} mt={2}>
              <Box sx={{ boxShadow: 3, borderRadius: '16px' }}>
                <Card sx={{ maxWidth: '100%' }}>
                  <CardContent>
                    <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Total Amount:</strong> {GBP.format(refundData?.totalAmount)}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Amount Refunded: </strong>
                      {GBP.format(refundData.amountRefunded)}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Currency: </strong>
                      <span style={{ textTransform: 'uppercase' }}>{refundData.currency}</span>
                    </Typography>
                    <strong>Refund Status:</strong>
                    <Label
                      variant="ghost"
                      sx={{ ml: 1 }}
                      color={getLabelColor(refundData?.refundStatus)}
                    >
                      {sentenceCase(
                        refundData?.refundStatus ? refundData?.refundStatus : 'unavailable'
                      )}
                    </Label>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong>Stripe Customer ID: </strong>
                      {refundData.customerId}
                    </Typography>
                    <Typography variant="body2" mt={3} mb={2} sx={{ fontSize: 17 }}>
                      <strong> Stripe Receipt Url: </strong>
                      <a href={refundData.receiptUrl} target="_blank">
                        View Receipt
                      </a>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17, fontStyle: 'italic' }}>
            No Refunds...
          </Typography>
        )}
        {refundData?.items?.length > 0 && (
          <Typography variant="h4" gutterBottom mt={3}>
            Refund Items
          </Typography>
        )}
        <Grid container display="flex">
          {refundData?.trxId &&
            refundData?.items &&
            refundData.items?.map((refundItem) => (
              <Grid key={refundItem.id} item lg={6} md={6} xs={12} sm={12} mt={2}>
                <Box sx={{ maxWidth: '98%', boxShadow: 3, borderRadius: '16px' }}>
                  <Card>
                    <CardContent>
                      <Typography
                        mt={1}
                        mb={2}
                        variant="body2"
                        sx={{ fontSize: 17, textTransform: 'capitalize' }}
                      >
                        <strong>Order Id:</strong> {refundItem.orderId ?? 'N/A'}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Charge Id:</strong> {refundItem.chargeId}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Amount:</strong> {refundItem.amount}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        Status:
                        <Label
                          variant="ghost"
                          sx={{ ml: 1 }}
                          color={getLabelColor(refundItem.status)}
                        >
                          {sentenceCase(refundItem.status ? refundItem.status : 'unavailable')}
                        </Label>
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Reason:</strong> {refundItem.reason}
                      </Typography>
                      <Typography variant="body2" mt={1} mb={2} sx={{ fontSize: 17 }}>
                        <strong>Date:</strong> {refundItem.created}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Page>
  );
};
export default withRole(OrderRefunds);

// const OrderItemLink = ({ orderId, orderItem }) => (
//   <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
//     {orderItem?.item_posts &&
//       orderItem?.item_posts?.length > 0 &&
//       orderItem?.item_posts.map((itemLink, idx) => (
//         <React.Fragment>
//           <ListItem
//             key={idx}
//             alignItems="flex-start"
//             secondaryAction={<OrderDetailActions orderId={orderId} itemPost={itemLink} />}
//           >
//             <ListItemText
//               primary={
//                 <React.Fragment>
//                   <Typography
//                     sx={{ display: 'inline' }}
//                     component="span"
//                     variant="body2"
//                     color="text.primary"
//                   >
//                     JAP Order ID:
//                   </Typography>
//                   {` — ${itemLink?.post_api_order_id}`}
//                   <br />
//                   <Typography
//                     sx={{ display: 'inline' }}
//                     component="span"
//                     variant="body2"
//                     color="text.primary"
//                   >
//                     Quantity:
//                   </Typography>
//                   {` — ${itemLink?.quantity}`}
//                   <br />
//                   <Typography
//                     sx={{ display: 'inline' }}
//                     component="span"
//                     variant="body2"
//                     color="text.primary"
//                   >
//                     Comments:
//                   </Typography>
//                   {` — ${itemLink?.comments}`}
//                 </React.Fragment>
//               }
//               secondary={
//                 <React.Fragment>
//                   <Typography
//                     sx={{ display: 'inline' }}
//                     component="span"
//                     variant="body2"
//                     color="text.primary"
//                   >
//                     Link:
//                   </Typography>
//                   {` — ${itemLink?.post_id}`}
//                 </React.Fragment>
//               }
//             />
//           </ListItem>
//           <Divider />
//         </React.Fragment>
//       ))}
//   </List>
// );
