import axios from 'axios';
import * as types from '../constants/actionTypes';
import { getAllOrders, getOrderDetails } from 'src/api/api';

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

// Get All Orders
export const allOrders = () => async (dispatch) => {
  try {
    const resp = await getAllOrders();
    if (resp.status === 200) {
      dispatch({
        type: types.GET_ORDERS_SUCCESS,
        payload: resp.data.orders
      });
    }
  } catch (err) {
    // console.log('🚀 ~ file: ordersActions.js:18 ~ allOrders ~ err:', err);
  }
};

// Get Single Order
export const singleOrder = (reqData) => {
  return async (dispatch) => {
    try {
      const resp = await getOrderDetails(reqData);
      if (resp.status === 200) {
        dispatch({
          type: types.SINGLE_ORDER_SUCCESS,
          payload: resp.data.order
        });
      }
    } catch (err) {}
  };
};
