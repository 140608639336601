import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import {
  getBlogCategories,
  createBlogCategory,
  getBlogCategory,
  updateBlogCategory,
  deleteBlogCategory
} from 'src/api/api';

// Get All Categories
export const getAllCategories = () => async (dispatch) => {
  try {
    const resp = await getBlogCategories();
    if (resp.status === 200) {
      const { categories } = resp.data;
      dispatch({
        type: types.ALL_BLOG_CATEGORIES,
        payload: categories || []
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({ type: types.ALL_BLOG_CATEGORIES, payload: [] });
  }
};

// Add Category
export const addBlogCategory = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createBlogCategory(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Blog category added successfully');
    }
    setTimeout(() => {
      navigate('/dashboard/blog-categories');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Get Single Category
export const singleCategory = (id) => async (dispatch) => {
  try {
    const resp = await getBlogCategory(id);
    if (resp.status === 200) {
      const { category = {} } = resp.data;
      dispatch({
        type: types.SINGLE_BLOG_CATE_SUCCESS,
        payload: category || {}
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({
      type: types.SINGLE_BLOG_CATE_SUCCESS,
      payload: {}
    });
  }
};

// Upadate Category
export const updateCategory = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updateBlogCategory(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data?.message : 'Blog category updated successfully');
      setTimeout(() => {
        navigate('/dashboard/blog-categories');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Delete Category
export const deleteCategory = (data, setLoading, setModalOpen) => async (dispatch) => {
  try {
    const resp = await deleteBlogCategory(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'Blog category deleted Successfully');
      dispatch({ type: types.DELETE_BLOG_CATE_SUCCESS, payload: { id: data.id } });
    }
    setLoading(false);
    setModalOpen(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};
