import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import {
  loginUser,
  isAuth as isAuthen,
  logout as logoutUser,
  getUserList,
  createUser,
  getUserById,
  updateUserInfo,
  deleteUserInfo,
  // profile
  updateProfileInfo,
  // roles
  getUserRoles,
  createUserRole,
  getUserRole,
  updateUserRoles,
  deleteUserRoles
} from 'src/api/api';

// Login User Account
export const loginAccount =
  (data, navigate, setLoading, redirectUri = '') =>
  async (dispatch) => {
    try {
      const res = await loginUser(data);
      if (res.status === 200) {
        dispatch({ type: types.LOGIN_USER_SUCCESS, payload: res.data.data });
        localStorage.setItem('userId', res.data.id);
        toast.success(res.data.message ? res.data.message : 'Login Successfull');
        setTimeout(() => {
          navigate(redirectUri ?? '/dashboard/app');
        }, 1500);
      } else {
        toast.error(res.data.message);
        setLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
      setLoading(false);
    }
  };

export const isAuth = () => async (dispatch) => {
  try {
    const { data } = await isAuthen();
    if (data.success) {
      dispatch({
        type: types.AUTH_SUCCESS,
        payload: data.user
      });
    }
  } catch (error) {
    dispatch({
      type: types.AUTH_FAILED,
      payload: null
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    const resp = await logoutUser();
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'User logged out successfully');
      dispatch({
        type: types.LOGOUT_USER_SUCCESS,
        payload: null
      });
      window.location.pathname = '/login';
    }
  } catch (error) {
    toast.error(error?.response?.data?.message ? error.response.data.message : error.message);
  }
};

// Create User
export const addUser = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createUser(data);
    if (resp.status === 200) {
      toast.success(resp.data.message);
      setTimeout(() => {
        navigate('/dashboard/users');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message);
    setLoading(false);
  }
};

//GET SINGLE USER
export const singleUser = (id) => {
  return async (dispatch) => {
    // dispatch({
    //   type: types.SINGLE_USER_ATTEMPT,
    //   payload: null
    // });
    try {
      const resp = await getUserById(id);
      if (resp.status === 200) {
        const { user = {}, roles = [] } = resp.data;
        dispatch({
          type: types.SINGLE_USER_SUCCESS,
          payload: { roles, ...user }
        });
      }
    } catch (err) {
      dispatch({
        type: types.SINGLE_USER_SUCCESS,
        payload: {}
      });
    }
  };
};

//GET USER LIST
export const fetchUserList = () => {
  return async (dispatch) => {
    // dispatch({
    //   type: types.GET_ALL_USERS_ATTEMPT,
    //   payload: null
    // });
    try {
      const resp = await getUserList();
      if (resp.status === 200) {
        const { users } = resp.data;
        dispatch({
          type: types.GET_ALL_USERS_SUCCESS,
          payload: users
        });
      }
    } catch (err) {
      dispatch({
        type: types.GET_ALL_USERS_SUCCESS,
        payload: []
      });
    }
  };
};

// UPDATE USER
export const updateUser = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updateUserInfo(data.id, data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'User Updated Successsully');
    }
    setTimeout(() => {
      navigate('/dashboard/users');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    setLoading(false);
  }
};

export const updateProfile = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updateProfileInfo(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Profile updated successfully');
    }
    setTimeout(() => {
      navigate('/dashboard/app');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    setLoading(false);
  }
};

// Delete User
export const deleteUser = (data) => async (dispatch) => {
  try {
    const resp = await deleteUserInfo(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'User Deleted Successsully');
      dispatch({ type: types.DELETE_USER_SUCCESS, payload: { id: data.id } });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
  }
};

// User Role Actions
// Add User Role
export const addRole = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createUserRole(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'User role added successfully');
      setTimeout(() => {
        navigate('/dashboard/user-role');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    setLoading(false);
  }
};

// Get All User Roles
export const allUserRoles = () => async (dispatch) => {
  try {
    const resp = await getUserRoles();
    if (resp.status === 200) {
      const { users_roles = [] } = resp.data;
      dispatch({
        type: types.GET_USER_ROLES_SUCCESS,
        payload: users_roles
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    dispatch({ type: types.GET_USER_ROLES_SUCCESS, payload: [] });
  }
};

// Get Single User Role
export const singleUserRole = (id) => async (dispatch) => {
  try {
    const resp = await getUserRole(id);
    if (resp.status === 200) {
      const { role = {} } = resp.data;
      dispatch({
        type: types.SINGLE_USER_ROLE_SUCCESS,
        payload: role
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    dispatch({ type: types.SINGLE_USER_ROLE_SUCCESS, payload: {} });
  }
};

// Update User Role
export const updateUserRole = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updateUserRoles(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'User role updated successfully');
    }
    setTimeout(() => {
      navigate('/dashboard/user-role');
    }, 1500);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    setLoading(false);
  }
};

// Delete User Role
export const deleteUserRole = (data, setLoading) => async (dispatch) => {
  try {
    const resp = await deleteUserRoles(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'User role deleted successfully');
      dispatch({ type: types.DELETE_USER_ROLE_SUCCESS, payload: { id: data.id } });
      setLoading(false);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err.response.data.message : err.message);
    setLoading(false);
  }
};
