import { toast } from 'react-toastify';
import * as types from '../constants/actionTypes';
import {
  getServicesCategories,
  createServiceCategory,
  getServiceCategory,
  updateServiceCategory,
  deleteServiceCategory
} from 'src/api/api';

// Add Category
export const addCategory = (data, navigate, setLoading) => async () => {
  try {
    const resp = await createServiceCategory(data);
    if (resp.status === 200) {
      toast.success(resp.data.message ? resp.data.message : 'Category Added Successfully');
      setTimeout(() => {
        navigate('/dashboard/package-category');
      }, 2000);
    }
    setLoading(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Get Single Category
export const singleCategory = (id) => async (dispatch) => {
  try {
    const resp = await getServiceCategory(id);
    if (resp.status === 200) {
      const { category = {}, services = [] } = resp.data;
      dispatch({
        type: types.SINGLE_CATEGORY_SUCCESS,
        payload: { ...category, services } || {}
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({
      type: types.SINGLE_CATEGORY_SUCCESS,
      payload: {}
    });
  }
};

// Get All Categories
export const getAllCategories = () => async (dispatch) => {
  try {
    const resp = await getServicesCategories();
    if (resp.status === 200) {
      dispatch({
        type: types.GET_CATEGORY_SUCCESS,
        payload: resp.data.serviceCategories || []
      });
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    dispatch({ type: types.GET_CATEGORY_SUCCESS, payload: [] });
  }
};

// Upadate Category
export const updateCategory = (data, navigate, setLoading) => async () => {
  try {
    const resp = await updateServiceCategory(data.id, data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data?.message : 'Category updated successfully');
      setTimeout(() => {
        navigate('/dashboard/package-category');
      }, 1500);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};

// Delete Category
export const deleteCategory = (data, setLoading, setModalOpen) => async (dispatch) => {
  try {
    const resp = await deleteServiceCategory(data);
    if (resp.status === 200) {
      toast.success(resp.data?.message ? resp.data.message : 'Category deleted Successfully');
      dispatch({ type: types.DELETE_CATEGORY_SUCCESS, payload: { id: data.id } });
    }
    setLoading(false);
    setModalOpen(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err.message);
    setLoading(false);
  }
};
