import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Stack,
  Box,
  Typography,
  Grid,
  FormLabel,
  Button,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
//
import BlogRTE from 'src/components/BlogRTE';
import Loader from 'src/components/Loader';
import { updateBlog, singleBlog } from 'src/Redux/actions/blogsActions'; // Actions
// ----------------------------------------------------------------------
import { validTypes } from './CreateBlogForm';

export default function EditBlogForm() {
  const navigate = useNavigate();
  const { slug: id } = useParams();
  const dispatch = useDispatch();
  const [postThumb, setPostThumb] = useState('');
  const pageState = useSelector((state) => state.blogs);
  const {
    loading,
    item: { categories = [], ...blog }
  } = pageState;

  useEffect(() => {
    dispatch(singleBlog(id));
  }, []);

  // Validation Schema
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Blog Title is required'),
    slug: Yup.string().required('Blog Slug is required'),
    body: Yup.string().required('Blog body is required')
  });

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (!validTypes.includes(file.type)) {
      toast.error('Please select an image, allowed file types are jpg, jpeg, png & webp.');
      return false;
    }
    setFieldValue('post_image', file);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setPostThumb(fileReader.result);
      }
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        {loading && <Loader />}
        {!loading && blog?.id && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={blog}
                validationSchema={BlogSchema}
                onSubmit={(values, { setSubmitting }) => {
                  const formData = new FormData();
                  Object.keys(values).forEach((dataItem) => {
                    formData.append(dataItem, values[dataItem]);
                  });
                  dispatch(updateBlog(values.id, formData, navigate, setSubmitting));
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  isSubmitting
                }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Edit Blog Post
                    </Typography>
                    <Stack spacing={3} mt={2}>
                      <TextField
                        type="text"
                        name="title"
                        label="Blog Title"
                        error={!!errors.title}
                        helperText={touched.title && errors.title && errors.title}
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Box width={'100%'}>
                        <TextField
                          fullWidth
                          type="text"
                          name="slug"
                          label="Blog Slug"
                          value={values.slug}
                          onChange={handleChange}
                        />
                      </Box>
                      <FormControl fullWidth>
                        <InputLabel id="blog-category">Blog Category</InputLabel>
                        <Select
                          labelId="blog-category"
                          id="blog-category-select"
                          value={values.category_id}
                          label="Blog Category"
                          onChange={(e) => setFieldValue('category_id', e.target.value)}
                        >
                          {categories.map((cate) => (
                            <MenuItem value={cate.id}>{cate.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        type="text"
                        id="meta_title"
                        name="meta_title"
                        label="Meta Title"
                        value={values.meta_title}
                        onChange={handleChange}
                      />
                      <TextField
                        type="text"
                        name="meta_description"
                        label="Meta Description"
                        value={values.meta_description}
                        onChange={handleChange}
                      />
                      <FormLabel id="body">Body</FormLabel>
                      <BlogRTE setFieldValue={setFieldValue} name="body" value={values.body} />
                      {errors.body && touched.body && (
                        <Typography color={'error'} variant="body2">
                          {errors.body}
                        </Typography>
                      )}
                      <FormLabel htmlFor="post_image">Post Image</FormLabel>
                      <TextField
                        id="post_image"
                        name="post_image"
                        type="file"
                        accept=".jpg, .jpeg, .png, .webp"
                        onChange={(e) => handleFileChange(e, setFieldValue)}
                      />
                      <Grid container spacing={3}>
                        {postThumb && (
                          <Grid item sm={12} md={6} mt={3}>
                            <Typography variant="body2" gutterBottom>
                              New Image Preview
                            </Typography>
                            <Box position={'relative'}>
                              <IconButton
                                aria-label="delete"
                                sx={{
                                  position: 'absolute',
                                  right: 0,
                                  color: 'common.white'
                                }}
                                onClick={() => (
                                  setFieldValue('post_image', blog.post_image), setPostThumb('')
                                )}
                              >
                                <Delete />
                              </IconButton>
                              <img
                                src={postThumb}
                                width={'100%'}
                                style={{
                                  objectPosition: 'top left',
                                  objectFit: 'contain'
                                }}
                                alt="blog post image"
                              />
                            </Box>
                          </Grid>
                        )}
                        {blog.post_image && (
                          <Grid item sm={12} md={6} mt={3}>
                            <Typography variant="body2" gutterBottom>
                              Current Image
                            </Typography>
                            <img
                              src={`${process.env.REACT_APP_SITE_URI_LOCAL}/assets/blogs_images/thumbnails/${blog.post_image}`}
                              width={'100%'}
                              style={{
                                objectPosition: 'top left',
                                objectFit: 'contain'
                              }}
                              alt="current post image"
                            />
                          </Grid>
                        )}
                      </Grid>
                      <FormLabel id="active">Status</FormLabel>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.status == 1}
                            onChange={() => setFieldValue('status', values.status == 1 ? 0 : 1)}
                          />
                        }
                        label={values.status == 1 ? 'Active' : 'Inactive'}
                      />
                    </Stack>
                    <Grid container>
                      <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={5} sm={5} md={3} lg={2} ml={3} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/blogs"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
