import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getUserRoles } from 'src/api/api';
import withRole from 'src/components/HOC/withRole';
import PageLoader from 'src/components/Loader';
import MuiFormikTextField from 'src/components/UI/MuiFormikField';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  FormLabel,
  MenuItem,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { addUser } from 'src/Redux/actions/authActions';

function CreateUser(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // To Check User Role
  const userRole = props.user.user_role;

  // Get All User Roles
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    getAllRoles();
    // Check User Role
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  const getAllRoles = async () => {
    try {
      setLoading(true);
      const resp = await getUserRoles();
      if (resp.status === 200) {
        const { users_roles } = resp.data;
        setUserRoles(users_roles);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Validation Schema for From
  const CreateUserSchema = Yup.object().shape({
    name: Yup.string().min(5, 'Name should be more than 5 words').required('User Name is required'),
    email: Yup.string().email('Must be a valid email').required('User Email is required'),
    password: Yup.string()
      .min(8, 'Password is too short should be at least 8 characters')
      .required('Password is required'),
    user_role: Yup.string().required('User role is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10} mx="auto">
        <Card sx={{ minWidth: 275, pt: 2, px: 3 }}>
          {loading && <PageLoader />}
          {!loading && (
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  name: '',
                  email: '',
                  password: '',
                  user_role: ''
                }}
                validationSchema={CreateUserSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(addUser(values, navigate, setSubmitting));
                }}
              >
                {({ errors, touched, isSubmitting, values, handleBlur, handleChange }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Create User
                    </Typography>
                    <Stack spacing={3} mt={2}>
                      {/* <FormLabel id="name">Name</FormLabel> */}
                      <Field
                        type="text"
                        name="name"
                        label="Name"
                        component={MuiFormikTextField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name && touched.name && errors.name}
                        placeholder="Enter User Name"
                      />
                      <Field
                        type="email"
                        name="email"
                        label="Email"
                        component={MuiFormikTextField}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email && touched.email && errors.email}
                        placeholder="Enter User Email"
                      />
                      <Field
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label="Password"
                        component={OutlinedInput}
                        error={!!errors.password}
                        helperText={errors.password && touched.password && errors.password}
                        placeholder="Enter Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {/* <FormLabel id="user_role">Role</FormLabel> */}
                      <Field
                        name="user_role"
                        select
                        component={MuiFormikTextField}
                        error={!!errors.user_role}
                        helperText={errors.user_role && errors.user_role}
                        label="Select Role"
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {userRoles?.map((item) => (
                          <MenuItem key={item.id} value={item.role_value} label={item.role_name}>
                            {item.role_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Stack>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/users"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default withRole(CreateUser);
