import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch } from 'formik-mui';
import * as Yup from 'yup';
import withRole from 'src/components/HOC/withRole';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  FormLabel,
  MenuItem,
  Card,
  FormControlLabel,
  CardContent
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import Loader from 'src/components/Loader';
import { singleStripeSetting, updateSetting } from 'src/Redux/actions/stripeSettingActions';

function EditStripeSetting(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // For User Role
  const userRole = props.user.user_role;
  const pageState = useSelector((state) => state.stripeSetting.stripeSetting);
  const isLoading = useSelector((state) => state.stripeSetting.loading);

  useEffect(() => {
    const fetchData = () => {
      dispatch(singleStripeSetting({ id }));
    };
    fetchData();
    // For User Role
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Validation Schema for From
  const StripeSettingsSchema = Yup.object().shape({
    companyName: Yup.string(),
    currency: Yup.string().required('Currency is required'),
    stripePublishableKey: Yup.string().required('Public Key is required'),
    stripeSecretKey: Yup.string().required('Secret key is required'),
    endpointSecret: Yup.string().required('Webhook Secret key is required')
  });

  return (
    <Grid container>
      <Grid item lg={8} md={8} xs={10} sm={10} mx="auto">
        {isLoading && <Loader />}
        {!loading && pageState?.id && (
          <Card sx={{ minWidth: 275, pt: 2, pl: 3 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: pageState?.id ? pageState?.id : '',
                  companyName: pageState?.companyName ? pageState?.companyName : '',
                  currency: pageState?.currency ? pageState?.currency : '',
                  stripePublishableKey: pageState?.stripePublishableKey
                    ? pageState?.stripePublishableKey
                    : '',
                  stripeSecretKey: pageState?.stripeSecretKey ? pageState?.stripeSecretKey : '',
                  endpointSecret: pageState?.endpointSecret ? pageState?.endpointSecret : '',
                  active: pageState?.active ? pageState?.active : 1,
                  sandbox: pageState?.sandbox ? pageState?.sandbox : 0
                }}
                validationSchema={StripeSettingsSchema}
                onSubmit={(values) => {
                  setLoading(true);
                  dispatch(updateSetting(values, navigate, setLoading));
                }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Edit Stripe Setting
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <FormLabel id="companyName">Company Name</FormLabel>
                      <Field
                        type="text"
                        id="companyName"
                        name="companyName"
                        className="input-style"
                        placeholder="Enter Company Name"
                      />
                      {errors.companyName && touched.companyName ? (
                        <div className="error-color">{errors.companyName}</div>
                      ) : null}

                      <FormLabel id="radios">Currency</FormLabel>
                      <Field
                        name="currency"
                        select
                        component={TextField}
                        value={values.currency}
                        sx={{ width: '95%' }}
                        label="Select Value"
                      >
                        <MenuItem value="£" label="GBP" key="1">
                          GBP
                        </MenuItem>
                        <MenuItem value="$" label="USD" key="2">
                          USD
                        </MenuItem>
                      </Field>
                      <FormLabel id="publicKey">Public Key</FormLabel>
                      <Field
                        type="text"
                        id="stripePublishableKey"
                        name="stripePublishableKey"
                        className="input-style"
                        placeholder="Enter Public Key"
                      />
                      {errors.stripePublishableKey && touched.stripePublishableKey ? (
                        <div className="error-color">{errors.stripePublishableKey}</div>
                      ) : null}

                      <FormLabel id="stripeSecretKey">Secret Key</FormLabel>
                      <Field
                        type="text"
                        id="stripeSecretKey"
                        name="stripeSecretKey"
                        className="input-style"
                        placeholder="Enter Secret Key"
                      />
                      {errors.stripeSecretKey && touched.stripeSecretKey ? (
                        <div className="error-color">{errors.stripeSecretKey}</div>
                      ) : null}

                      <FormLabel id="endpointSecret">Webhook Secret</FormLabel>
                      <Field
                        type="text"
                        id="endpointSecret"
                        name="endpointSecret"
                        className="input-style"
                        placeholder="Enter endpointSecret Secret Key"
                      />
                      {errors.endpointSecret && touched.endpointSecret ? (
                        <div className="error-color">{errors.endpointSecret}</div>
                      ) : null}

                      <Grid>
                        <FormLabel id="sandBox">SandBox</FormLabel>
                        <Grid>
                          <Grid item lg={5} md={5} xs={10} sm={10}>
                            <FormControlLabel
                              checked={values?.sandbox == 1}
                              control={<Field component={Switch} type="checkbox" name="sandbox" />}
                              label={values.sandbox == 1 ? 'SandBox' : 'Non-SandBox'}
                              onChange={(e) => setFieldValue('sandbox', e.target.checked ? 1 : 0)}
                            />
                          </Grid>
                        </Grid>
                        <FormLabel id="active">Active</FormLabel>
                        <Grid item lg={5} md={5} xs={10} sm={10}>
                          <FormControlLabel
                            checked={values?.active == 1}
                            control={<Field component={Switch} type="checkbox" name="active" />}
                            label={values?.active == 1 ? 'Active' : 'In-Active'}
                            onChange={(e) => setFieldValue('active', e.target.checked ? 1 : 0)}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={loading}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          disabled={loading}
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/dashboard/stripe-setting"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

export default withRole(EditStripeSetting);
