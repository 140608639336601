import React from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
// material
import {
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  FormLabel,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { addService } from 'src/Redux/actions/serviceActions';

export default function AddService() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Validation Schema for From
  const ServiceSchema = Yup.object().shape({
    name: Yup.string().required('Service Name is required')
  });

  return (
    <Grid container>
      <Grid lg={8} md={8} xs={10} sm={10} mx="auto" item>
        <Card sx={{ minWidth: 275, boxShadow: 3, borderRadius: 2, p: 3 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: '',
                cat_slug: '',
                active: true
              }}
              validationSchema={ServiceSchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(addService(values, navigate, setSubmitting));
              }}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                isSubmitting,
                handleChange,
                handleBlur
              }) => (
                <Form autoComplete="off">
                  <Typography variant="h4" gutterBottom>
                    Add Package Service
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <TextField
                      type="text"
                      name="name"
                      label={'Service Name'}
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      helperText={errors.name && touched.name && errors.name}
                      disabled={isSubmitting}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      type="text"
                      name="cat_slug"
                      label={'Service Slug'}
                      variant="outlined"
                      onChange={handleChange}
                      value={values.cat_slug}
                      disabled={isSubmitting}
                      fullWidth
                      margin="normal"
                    />
                    <FormControlLabel
                      sx={{ justifyContent: 'start' }}
                      control={
                        <Switch
                          checked={values.active == '1'}
                          onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                          disabled={isSubmitting}
                        />
                      }
                      label={values.active ? 'Active' : 'Disbaled'}
                      labelPlacement="start"
                    />
                  </Stack>
                  <Grid container>
                    <Grid xs={4} sm={4} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        to="/dashboard/package-category"
                        disabled={isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
