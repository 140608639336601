import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// material
import { Grid, Container, Stack, Typography, Button, Box, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// components
import Page from 'src/components/Page';
//

const UserProfile = () => {
  // Single User
  // const dispatch = useDispatch();
  const pageState = useSelector((state) => state.auth);

  const userRole = pageState.user?.userRole;

  return (
    <Page title="Dashboard: Pages | User Profile">
      <Container>
        <Stack direction="row" justifyContent="space-around" mb={2}>
          <Typography variant="h4" gutterBottom>
            User Details
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to="/dashboard/app"
            startIcon={<ArrowBackIcon />}
          >
            Go Back to Dashboard
          </Button>
        </Stack>
        {pageState?.user?.id && (
          <Grid container display="flex" alignItems="center" justifyContent="center" spacing={1}>
            <Grid lg={6} md={6} xs={10} sm={10} mt={2}>
              <Box sx={{ boxShadow: 3, borderRadius: '16px' }}>
                <Card sx={{ maxWidth: '100%', textAlign: 'center' }}>
                  <CardContent>
                    <Grid display="flex" justifyContent="end">
                      <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to="/dashboard/user-profile/edit"
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Stack direction="row" justifyContent="center" spacing={2}>
                      <Avatar
                        aria-label="recipe"
                        src="/static/mock-images/avatars/avatar_default.jpg"
                        sx={{ width: 70, height: 70, mt: 4 }}
                      />
                    </Stack>

                    <Typography
                      variant="body2"
                      mt={1}
                      mb={1}
                      className="user-detail-fs"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      <strong>{pageState.user?.name}</strong>
                    </Typography>
                    <Typography variant="body2" mb={2} className="user-detail-fs">
                      {pageState.user?.email}
                    </Typography>
                    <Typography variant="body2" mt={1} mb={2} className="user-detail-fs">
                      <span className="user-detail-role">
                        {userRole === 1 ? 'Admin' : userRole === 2 ? 'SEO' : 'User'}
                      </span>
                    </Typography>
                    <Typography variant="body2" mt={1} mb={2} className="user-detail-fs">
                      {moment(pageState.user?.updated_at).format('MMMM Do YYYY')}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};
export default UserProfile;
