import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import Loader from 'src/components/Loader';
import withRole from 'src/components/HOC/withRole';
// material
import { Stack, Typography, Button, Grid, FormLabel, MenuItem, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { updateUser, singleUser } from 'src/Redux/actions/authActions';

function EditUser(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = props.user.user_role;

  // Get All User Roles
  useEffect(() => {
    dispatch(singleUser(id));
    //
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Get Single User
  const pageState = useSelector((state) => state.auth);
  const isLoading = pageState.loading;
  const { roles, ...user } = pageState.item;

  // Validation Schema for From
  const EidtUserSchema = Yup.object().shape({
    name: Yup.string().min(5, 'Name should be more than 5 words').required('User Name is required'),
    email: Yup.string().email('Must be a valid email').required('User Email is required'),
    password: Yup.string().min(8, 'Password is too short should be at least 8 characters'),
    user_role: Yup.string().required('User role is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10} mx="auto">
        {isLoading && <Loader />}
        {!isLoading && user?.id && (
          <Card sx={{ minWidth: 275, pt: 2, pl: 3 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: user ? user.id : '',
                  name: user ? user.name : '',
                  email: user ? user.email : '',
                  user_role: user ? user?.user_role : '',
                  password: ''
                }}
                validationSchema={EidtUserSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updateUser(values, navigate, setSubmitting));
                }}
              >
                {({ errors, touched, isSubmitting }) => (
                  <Form autoComplete="off">
                    {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                    <Typography variant="h4" gutterBottom>
                      Edit User
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <FormLabel id="name">Name</FormLabel>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        className="input-style"
                        placeholder="Enter User Name"
                      />
                      {errors.name && touched.name ? (
                        <div className="error-color">{errors.name}</div>
                      ) : null}

                      <Tooltip title="Cannot Update Email">
                        <FormLabel id="email">Email</FormLabel>
                      </Tooltip>
                      <Field
                        type="text"
                        id="email"
                        name="email"
                        className="input-style"
                        placeholder="Enter User Email"
                        disabled
                      />
                      {errors.email && touched.email ? (
                        <div className="error-color">{errors.email}</div>
                      ) : null}
                      <FormLabel id="password">Password</FormLabel>
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        className="input-style"
                        placeholder="Enter Password"
                      />

                      {errors.password && touched.password ? (
                        <div className="error-color">{errors.password}</div>
                      ) : null}
                      <FormLabel id="radios">Role</FormLabel>
                      <Field
                        name="user_role"
                        select
                        component={TextField}
                        // sx={{ width: '95%' }}
                        label="Select Value"
                      >
                        <MenuItem value="">Not Selected</MenuItem>
                        {roles?.map((item) => {
                          return (
                            <MenuItem value={item.role_value} label={item.role_value} key={item.id}>
                              {item.role_name}
                            </MenuItem>
                          );
                        })}
                      </Field>
                      {errors.user_role && touched.user_role ? (
                        <div className="error-color">{errors.user_role}</div>
                      ) : null}
                    </Stack>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/users"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

export default withRole(EditUser);
