import { combineReducers } from 'redux';

import authReducer from './authReducer';
import servicesReducer from './servicesReducer';
import categoryReducer from './categoryReducer';
import packagesReducer from './packagesReducer';
// import pageSectionReducer from './pageSectionReducer';
// import faqsReducer from './faqsReducer';
// import reviewsReducer from './reviewsReducer';
// import addonReducer from './addonReducer';
import blogsReducer from './blogsReducer';
import blogCategoryReducer from './blogCategoryReducer';
import ordersReducer from './ordersReducer';
import refundsReducer from './refundsReducer';
import stripeSettingReducer from './stripeSettingReducer';
import couponsReducer from './couponsReducer';

export default combineReducers({
  auth: authReducer,
  services: servicesReducer,
  categories: categoryReducer,
  packages: packagesReducer,
  // pageSections: pageSectionReducer,
  // faqs: faqsReducer,
  blogs: blogsReducer,
  blogCategories: blogCategoryReducer,
  // reviews: reviewsReducer,
  orders: ordersReducer,
  refunds: refundsReducer,
  stripeSetting: stripeSettingReducer,
  coupons: couponsReducer
});
