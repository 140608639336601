import React from 'react';
// material
import { getUserList } from 'src/api/api';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Box, CircularProgress } from '@mui/material';
// component
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppNewUsers() {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  // Get All users
  React.useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const resp = await getUserList();
      if (resp.status === 200) {
        setUsers(resp.data.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Iconify icon="mdi:account-group" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={30} />
          </Box>
        ) : (
          users?.length
        )}
      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Users
      </Typography>
    </RootStyle>
  );
}
