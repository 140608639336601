import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Container, Stack, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
// components
import Page from 'src/components/Page';
import Loader from 'src/components/Loader';
import AddIcon from '@mui/icons-material/Add';
import Label from 'src/components/Label';
// ---------------------------- Action --------------------------------
import { getAllCategories } from 'src/Redux/actions/blogCategoryActions';
import ActionButtonBlogs from './components/ActionButtonBlogs';
//

const BlogCategories = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.blogCategories);
  const { items: categories = [], loading } = state;
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);

  // Get All Blogs
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    setRows(categories);
  }, [categories]);

  // Columns for Categories
  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'slug', headerName: 'Slug', width: 200 },
    {
      field: 'count',
      headerName: 'Blog Count',
      width: 130
    },
    {
      field: 'active',
      headerName: 'Active',
      width: 80,
      renderCell: (rowData) => (
        <Label color={rowData.row.active == 1 ? 'success' : 'error'}>
          {rowData.row.active == 1 ? 'Active' : 'In-Active'}
        </Label>
      )
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 130,
      renderCell: (rowData) => new Date(rowData.row.created_at).toDateString()
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 140,
      renderCell: (value) => (
        <ActionButtonBlogs
          value={value.row.action}
          slug={value.row.slug}
          id={value.row.id}
          name="blogs"
        />
      )
    }
  ];

  // Searchable Filters
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = categories.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]);
      });
    });
    setRows(filteredRows);
  };

  return (
    <Page title="Dashboard: Pages | Blog Categories">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Blog Categories Page
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/dashboard/blog-categories/add-blog-category"
            startIcon={<AddIcon />}
          >
            Add New
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" gutterBottom>
            Blog Category List
          </Typography>
          <TextField
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? 'visible' : 'hidden',
                    borderRadius: '57%',
                    paddingRight: '1px',
                    margin: '0',
                    fontSize: '1.25rem'
                  }}
                  onClick={() => {
                    setSearchText('');
                    setRows(categories);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              )
            }}
            sx={{
              width: { xs: 1, sm: 'auto' },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider'
              }
            }}
          />
        </Stack>
        {loading ? (
          <Loader />
        ) : (
          <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
            <DataGrid
              pageSize={20}
              rowsPerPageOptions={[20]}
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              autoHeight={true}
              disableSelectionOnClick
              disableColumnMenu
              // checkboxSelection
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
};
export default BlogCategories;
