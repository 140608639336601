import * as React from 'react';
//
import { styled, alpha } from '@mui/material/styles';
import { toast } from 'react-toastify';
import {
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// MUI ICONS
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { placeJapItemPostOrder, updateOrderPostLinkData } from 'src/api/api';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  resize: 'vertical',
  borderRadius: 8,
  border: '1px solid #E0E3E7',
  fontSize: 16,
  width: '100%',
  padding: '10px 12px',
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:focus': {
    borderColor: theme.palette.primary.main
  }
}));

export default function OrderDetailActions({ orderId, itemPost }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [itemPostEditDialog, setItemPostEditDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <IconButton edge="end" aria-label="comments" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleClickOpen} disableRipple>
          <RestartAltSharpIcon />
          JAP Order Request
        </MenuItem>
        <MenuItem
          onClick={() => {
            setItemPostEditDialog(true);
            setAnchorEl(null);
          }}
          disableRipple
        >
          <CurrencyExchangeIcon />
          Edit
        </MenuItem>
      </StyledMenu>
      <PlaceOrderDialog
        modalOpen={modalOpen}
        closeModal={closeModal}
        orderId={orderId}
        itemPost={itemPost}
      />
      <EditItemLinkDialog
        modalOpen={itemPostEditDialog}
        closeModal={setItemPostEditDialog}
        orderId={orderId}
        itemPost={itemPost}
      />
    </div>
  );
}

const PlaceOrderDialog = ({ modalOpen, closeModal, orderId, itemPost }) => {
  // const navigate = useNavigate();
  // const [responseData, setResponseData] = React.useState(null);
  const [placingOrder, setPlacingOrder] = React.useState(false);

  React.useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const placeJapOrder = async () => {
    try {
      setPlacingOrder(true);
      const resp = await placeJapItemPostOrder({
        order_id: orderId,
        item_post_id: itemPost?.item_post_id
      });
      if (resp.status === 200) {
        toast.success(resp.data.message);
        setTimeout(() => {
          handleCloseModal();
          window.location.reload();
        }, 1500);
      }
      setPlacingOrder(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ? error.response.data.message : error.message);
      setPlacingOrder(false);
    }
  };

  const handleCloseModal = () => {
    if (!placingOrder) closeModal();
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Place order on JAP?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to place order on JAP?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={placingOrder} variant="outlined" onClick={handleCloseModal}>
          No
        </LoadingButton>
        <LoadingButton loading={placingOrder} variant="contained" onClick={placeJapOrder} autoFocus>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const EditItemLinkDialog = ({ modalOpen, closeModal, orderId, itemPost }) => {
  // const navigate = useNavigate();
  // const [responseData, setResponseData] = React.useState(null);
  const [itemPostData, setItemPostData] = React.useState(itemPost);
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const handleCreateRefund = async () => {
    try {
      setUpdating(true);
      const resp = await updateOrderPostLinkData({ order_id: orderId, ...itemPostData });
      if (resp.status === 200) {
        toast.success(resp.data.message);
        setTimeout(() => {
          handleCloseModal();
          window.location.reload();
        }, 1500);
      }
      setUpdating(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ? error.response.data.message : error.message);
      setUpdating(false);
    }
  };

  const handleCloseModal = () => {
    if (!updating) closeModal();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update Post Link Data?</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          size="sm"
          margin="dense"
          name="quantity"
          label="Quantity"
          type="number"
          fullWidth
          value={itemPostData?.quantity}
          onChange={(e) => {
            const value = e.target.value;
            setItemPostData({
              ...itemPostData,
              quantity: value
            });
          }}
        />
        <TextField
          autoFocus
          size="sm"
          margin="dense"
          name="post_id"
          label="Link"
          type="text"
          fullWidth
          value={itemPostData?.post_id}
          onChange={(e) => {
            const value = e.target.value;
            setItemPostData({
              ...itemPostData,
              post_id: value
            });
          }}
        />
        <StyledTextArea
          autoFocus
          size="sm"
          margin="dense"
          name="comments"
          label="Comments"
          placeholder="Write your comments..."
          type="text"
          fullWidth
          value={itemPostData?.comments}
          onChange={(e) => {
            const value = e.target.value;
            setItemPostData({
              ...itemPostData,
              comments: value
            });
          }}
        />
        <TextField
          autoFocus
          size="sm"
          margin="dense"
          name="post_api_order_id"
          label="JAP ID"
          type="text"
          fullWidth
          value={itemPostData?.post_api_order_id}
          onChange={(e) => {
            const value = e.target.value;
            setItemPostData({
              ...itemPostData,
              post_api_order_id: value
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={updating} variant="outlined" onClick={handleCloseModal}>
          No
        </LoadingButton>
        <LoadingButton
          loading={updating}
          variant="contained"
          onClick={handleCreateRefund}
          autoFocus
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
