import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import withRole from 'src/components/HOC/withRole';
// material
import {
  Grid,
  Stack,
  Button,
  Typography,
  FormLabel,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import { addRole } from 'src/Redux/actions/authActions';

function CreateUser(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Get User Role
  const userRole = props.user.user_role;

  // To check User Role
  useEffect(() => {
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Validation Schema for From
  const CreateUserRoleSchema = Yup.object().shape({
    role_name: Yup.string().required('Role name is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10} mx="auto">
        <Card sx={{ minWidth: 275, pt: 2, pl: 3 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                role_name: '',
                status: 1
              }}
              validationSchema={CreateUserRoleSchema}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(addRole(values, navigate, setSubmitting));
              }}
            >
              {({ errors, touched, setFieldValue, values, isSubmitting }) => (
                <Form autoComplete="off">
                  <Typography variant="h4" gutterBottom>
                    Create New Role
                  </Typography>
                  <Stack spacing={1} mt={2}>
                    <FormLabel id="role_name">Role Name</FormLabel>
                    <Field
                      type="text"
                      id="role_name"
                      name="role_name"
                      className="input-style"
                      placeholder="Enter Role Name"
                    />
                    {errors.role_name && touched.role_name && (
                      <div className="error-color">{errors.role_name}</div>
                    )}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.status == 1}
                          onChange={() => setFieldValue('status', values.status == 1 ? 0 : 1)}
                        />
                      }
                      label={values.status == 1 ? 'Active' : 'Inactive'}
                    />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={2} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        disabled={isSubmitting}
                        to="/dashboard/user-role"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withRole(CreateUser);
