import { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import withRole from 'src/components/HOC/withRole';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  FormLabel,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import Loader from 'src/components/Loader';
import { singleUserRole, updateUserRole } from 'src/Redux/actions/authActions';

function EditUserRole(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.auth);
  const { userRole: roleData, loading } = pageState;
  // Get User Role
  const userRole = props.user.user_role;

  useEffect(() => {
    dispatch(singleUserRole(id));
    //
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  // Validation Schema for From
  const UserRoleSchema = Yup.object().shape({
    role_name: Yup.string().required('Role name is required')
  });

  return (
    <Grid container justifyContent="center">
      <Grid item lg={8} md={8} xs={10} sm={10} mx="auto">
        <Card sx={{ minWidth: 275, pt: 2, pl: 3 }}>
          <CardContent>
            {loading ? (
              <Loader />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: id,
                  role_name: roleData?.role_name ? roleData?.role_name : '',
                  status: roleData?.status ? roleData?.status : 1
                }}
                validationSchema={UserRoleSchema}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updateUserRole(values, navigate, setSubmitting));
                }}
              >
                {({ errors, touched, values, isSubmitting, setFieldValue }) => (
                  <Form autoComplete="off">
                    <Typography variant="h4" gutterBottom>
                      Edit User Role
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <FormLabel id="role_name">Role Name</FormLabel>
                      <Field
                        type="text"
                        id="role_name"
                        name="role_name"
                        className="input-style"
                        placeholder="Enter Role Name"
                      />
                      {errors.role_name && touched.role_name && (
                        <div className="error-color">{errors.role_name}</div>
                      )}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.status == 1}
                            onChange={() => setFieldValue('status', values.status == 1 ? 0 : 1)}
                          />
                        }
                        label={values.status == 1 ? 'Active' : 'Inactive'}
                      />
                    </Stack>
                    <Grid container>
                      <Grid item xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          disabled={isSubmitting}
                          to="/dashboard/user-role"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withRole(EditUserRole);
