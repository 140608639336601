import * as React from 'react';
//
import { styled, alpha } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// MUI ICONS
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import PageviewSharpIcon from '@mui/icons-material/PageviewSharp';
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { placeJapCartOrder, createStripeRefund } from 'src/api/api';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

export default function OrderActionButton({ id, orderId, amount, trxId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [refundModalOpen, setRefundModalOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleClickOpen} disableRipple>
          <RestartAltSharpIcon />
          JAP Order Request
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRefundModalOpen(true);
            setAnchorEl(null);
          }}
          disableRipple
        >
          <AddToHomeScreenIcon />
          Create Refund
        </MenuItem>
      </StyledMenu>
      <PlaceOrderDialog modalOpen={modalOpen} closeModal={closeModal} orderId={orderId} />
      <StripeRefundDialog
        modalOpen={refundModalOpen}
        closeModal={setRefundModalOpen}
        orderId={orderId}
        amount={amount}
      />
    </div>
  );
}

const PlaceOrderDialog = ({ modalOpen, closeModal, orderId }) => {
  const [placingOrder, setPlacingOrder] = React.useState(false);

  React.useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const placeJapOrder = async () => {
    try {
      setPlacingOrder(true);
      const resp = await placeJapCartOrder({ order_id: orderId });
      if (resp.status === 200) {
        toast.success(resp.data.message);
        setTimeout(() => handleCloseModal(), 500);
      }
      setPlacingOrder(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ? error.response.data.message : error.message);
      setPlacingOrder(false);
    }
  };

  const handleCloseModal = () => {
    if (!placingOrder) closeModal();
  };
  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Place order on JAP?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to place order on JAP?
          {/* {responseData && (
            <span>
              <br />
              Order JAP Response:
              <code>{JSON.stringify(responseData, undefined, 2)}</code>
            </span>
          )} */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={placingOrder} variant="outlined" onClick={handleCloseModal}>
          No
        </LoadingButton>
        <LoadingButton loading={placingOrder} variant="contained" onClick={placeJapOrder} autoFocus>
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const StripeRefundDialog = ({ modalOpen, closeModal, orderId, amount }) => {
  // const navigate = useNavigate();
  // const [responseData, setResponseData] = React.useState(null);
  const [refundData, setRefundData] = React.useState({
    order_id: orderId,
    amount: amount,
    reason: 'requested_by_customer'
  });
  const [refunding, setRefunding] = React.useState(false);

  React.useEffect(() => {
    return () => {
      handleCloseModal();
    };
  }, []);

  const handleCreateRefund = async () => {
    try {
      setRefunding(true);
      const resp = await createStripeRefund(refundData);
      if (resp.status === 200) {
        toast.success(resp.data.message);
        setTimeout(() => handleCloseModal(), 500);
      }
      setRefunding(false);
    } catch (error) {
      toast.error(error?.response?.data?.message ? error.response.data.message : error.message);
      setRefunding(false);
    }
  };

  const handleCloseModal = () => {
    if (!refunding) closeModal();
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Create Stripe Refund?</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          size="sm"
          margin="dense"
          name="amount"
          label="Amount"
          type="number"
          disabled={refunding}
          max={refundData.amount}
          fullWidth
          value={refundData.amount}
          onChange={(e) => {
            const value = e.target.value;
            if (Number(value) > amount) return false;
            setRefundData({
              ...refundData,
              amount: value
            });
          }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="refund-reason">Reason</InputLabel>
          <Select
            labelId="refund-reason"
            id="refund-reason-select"
            value={refundData.reason}
            label="Reason"
            disabled={refunding}
            onChange={(e) => {
              const value = e.target.value;
              setRefundData({
                ...refundData,
                reason: value
              });
            }}
          >
            <MenuItem value={'duplicate'}>Duplicate</MenuItem>
            <MenuItem value={'fraudulent'}>Fraudulent</MenuItem>
            <MenuItem value={'requested_by_customer'}>Requested By Customer</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={refunding} variant="outlined" onClick={handleCloseModal}>
          No
        </LoadingButton>
        <LoadingButton
          loading={refunding}
          variant="contained"
          onClick={handleCreateRefund}
          autoFocus
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
