import DashboardIcon from '@mui/icons-material/Dashboard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DiscountIcon from '@mui/icons-material/Discount';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

const isAdmin = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />
  },
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: <MonetizationOnIcon />
  },
  {
    title: 'stripe Setting',
    path: '/dashboard/stripe-setting',
    icon: <RoomPreferencesIcon />
  },
  {
    title: 'Services',
    path: '/dashboard/packages',
    icon: <HomeRepairServiceIcon />,
    subItem: [
      {
        item: [
          {
            title: 'Services',
            path: '/dashboard/services',
            icon: <HomeRepairServiceIcon />
          },
          {
            title: 'Service Categories',
            path: '/dashboard/package-category',
            icon: <CategoryIcon />
          },
          {
            title: 'Packages',
            path: '/dashboard/packages',
            icon: <InventoryIcon />
          }
        ]
      }
    ]
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: <ManageAccountsIcon />,
    subItem: [
      {
        item: [
          {
            title: 'Users',
            path: '/dashboard/users',
            icon: <GroupIcon />
          },
          {
            title: 'Users Roles',
            path: '/dashboard/user-role',
            icon: <Diversity3Icon />
          }
        ]
      }
    ]
  },
  // {
  //   title: 'Coupons',
  //   path: '/dashboard/coupons',
  //   icon: <DiscountIcon />
  // },
  {
    title: 'Blogs',
    path: '/dashboard/blogs',
    icon: <DocumentScannerIcon />,
    subItem: [
      {
        item: [
          {
            title: 'Blogs',
            path: '/dashboard/blogs',
            icon: <DocumentScannerIcon />
          },
          {
            title: 'Blog Categories',
            path: '/dashboard/blog-categories',
            icon: <CategoryIcon />
          }
        ]
      }
    ]
  }
  // {
  //   title: 'Faqs',
  //   path: '/dashboard/pages/faqs',
  //   icon: getIcon('wpf:faq')
  // },
  // {
  //   title: 'Reviews',
  //   path: '/dashboard/reviews',
  //   icon: getIcon('ic:baseline-reviews')
  // }
];

const isUser = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />
  },
  {
    title: 'Services',
    path: '/dashboard/packages',
    icon: <HomeRepairServiceIcon />,
    subItem: [
      {
        item: [
          {
            title: 'Services',
            path: '/dashboard/services',
            icon: <HomeRepairServiceIcon />
          },
          {
            title: 'Service Categories',
            path: '/dashboard/package-category',
            icon: <CategoryIcon />
          },
          {
            title: 'Packages',
            path: '/dashboard/packages',
            icon: <InventoryIcon />
          }
        ]
      }
    ]
  },
  // {
  //   title: 'Coupons',
  //   path: '/dashboard/coupons',
  //   icon: <DiscountIcon />
  // },
  {
    title: 'Blogs',
    path: '/dashboard/blogs',
    icon: <DocumentScannerIcon />
  }
];
// Check the user role

export default function sidebarConfig(role) {
  return role == '1' ? isAdmin : isUser;
}
