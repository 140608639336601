import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import withRole from 'src/components/HOC/withRole';
import { Stack, Typography, Button, Container } from '@mui/material';
import { allUserRoles } from 'src/Redux/actions/authActions';
//
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import RoleActionButton from './components/RoleActionButton';
import Iconify from 'src/components/Iconify';

function UserRoles(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.auth);
  const { userRoles, loading } = state;
  // To Check User Role
  const userRole = props.user.user_role;

  React.useEffect(() => {
    dispatch(allUserRoles());
    // Check User Role
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      return type;
    };
  }, []);

  const columns = [
    { field: 'role_name', headerName: 'Role Name', width: 240 },
    {
      field: 'status',
      headerName: 'Status',
      width: 240,
      renderCell: (rowData) => (
        <Label color={rowData.row.status == 1 ? 'success' : 'error'}>
          {rowData.row.status == 1 ? 'Active' : 'Inactive'}
        </Label>
      )
    },
    {
      field: 'created_at',
      headerName: 'Date',
      width: 260,
      renderCell: (rowData) => moment(rowData.row.created_at).format('MMMM Do, YYYY')
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (rowData) => <RoleActionButton id={rowData.row.id} />
    }
  ];

  return (
    <Page title="Dashboard: Users Role">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            User Roles
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/user-role/add-user-role"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Role
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" gutterBottom>
            All Roles
          </Typography>
        </Stack>
        <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
          <DataGrid
            rows={userRoles}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            // checkboxSelection
            autoHeight={true}
          />
        </Stack>
      </Container>
    </Page>
  );
}

export default withRole(UserRoles);
