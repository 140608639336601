import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Typography, Button, Container } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
// Components
import Label from 'src/components/Label';
import { getAllPackages } from 'src/Redux/actions/packageActions';
import Loader from 'src/components/Loader';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import ActionButtonPackages from './components/ActionButtonPackages';

export default function Packages() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.packages);
  const isLoading = state.loading;
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);

  // Get All Packages
  React.useEffect(() => {
    dispatch(getAllPackages());
  }, []);

  React.useEffect(() => {
    setRows(state.items);
  }, [state.items]);

  const columns = [
    {
      field: 'packageTitle',
      headerName: 'Package Title',
      width: 160
    },
    { field: 'package_slug', headerName: 'Package Slug', width: 170 },
    { field: 'packagePrice', headerName: 'Price', width: 80 },
    { field: 'priceUnit', headerName: 'Price Unit', width: 90 },
    { field: 'packageQty', headerName: 'Quantity', width: 90 },
    { field: 'serviceType', headerName: 'Service Type', width: 120 },
    {
      field: 'active',
      headerName: 'Active',
      width: 110,
      renderCell: (value) => (
        <Label color={value.row.active == 1 ? 'success' : 'error'}>
          {value.row.active == 1 ? 'Active' : 'In-Active'}
        </Label>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (value) => <ActionButtonPackages value={value.row.action} id={value.row.id} />
    }
  ];

  // Searchable Filters
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = state.items?.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    // console.log('🚀 ~ file: Packages.js:77 ~ filteredRows ~ filteredRows:', filteredRows);
    setRows(filteredRows);
  };

  return (
    <Page title="Dashboard: Packages">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            All Packages
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/packages/add-package"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Package
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h6" gutterBottom>
            Packages Table
          </Typography>
          <TextField
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? 'visible' : 'hidden',
                    borderRadius: '57%',
                    paddingRight: '1px',
                    margin: '0',
                    fontSize: '1.25rem'
                  }}
                  onClick={(e) => {
                    setSearchText('');
                    setRows(state.items);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              )
            }}
            sx={{
              width: { xs: 1, sm: 'auto' },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider'
              }
            }}
          />
        </Stack>
        {isLoading ? (
          <Loader />
        ) : (
          <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
            <DataGrid
              disableSelectionOnClick
              disableColumnMenu
              // checkboxSelection
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              autoHeight={true}
            />
          </Stack>
        )}
      </Container>
    </Page>
  );
}
