import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// material
import {
  Stack,
  Typography,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
//
import Loader from 'src/components/Loader';
import { singleService, updateService } from 'src/Redux/actions/serviceActions';

export default function EditService() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const loading = services.loading;

  useEffect(() => {
    const fetchData = () => {
      dispatch(singleService(id));
    };
    fetchData();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Service Name is required')
  });

  return (
    <Grid container>
      <Grid lg={10} md={10} xs={10} sm={10} mx="auto">
        {loading && <Loader />}
        {!loading && services.item?.id && (
          <Card sx={{ minWidth: 275, px: 5, pt: 2 }}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={{ id, ...services.item }}
                onSubmit={(values, { setSubmitting }) => {
                  dispatch(updateService(values, navigate, setSubmitting));
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting,
                  handleChange,
                  handleBlur
                }) => (
                  <Form autoComplete="off">
                    <Typography variant="h4" gutterBottom>
                      Update Package Service
                    </Typography>
                    <Stack spacing={1} mt={2}>
                      <TextField
                        type="text"
                        name="name"
                        label={'Service Name'}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        type="text"
                        name="cat_slug"
                        label={'Service Slug'}
                        variant="outlined"
                        onChange={handleChange}
                        value={values.cat_slug}
                        disabled={isSubmitting}
                        fullWidth
                        margin="normal"
                      />
                      <FormControlLabel
                        sx={{ justifyContent: 'start' }}
                        control={
                          <Switch
                            checked={values.active == '1'}
                            onChange={() => setFieldValue('active', values.active == 1 ? 0 : 1)}
                            disabled={isSubmitting}
                          />
                        }
                        label={values.active ? 'Active' : 'Disbaled'}
                        labelPlacement="start"
                      />
                    </Stack>
                    <Grid container>
                      <Grid xs={4} sm={4} md={3} lg={2} mt={3}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                      <Grid xs={4} sm={4} md={3} lg={2} ml={2} mt={3}>
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="error"
                          disabled={isSubmitting}
                          component={RouterLink}
                          to="/dashboard/services"
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}
