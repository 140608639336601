import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Typography, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { GBP } from 'src/utils/formatNumber';
import { sentenceCase } from 'change-case';
import withRole from 'src/components/HOC/withRole';
import Label from 'src/components/Label';
import Loader from 'src/components/Loader';
import OrderActionButton from './components/OrderActionButton';
import Page from 'src/components/Page';
//
import { allOrders } from 'src/Redux/actions/ordersActions';

export const getLabelColor = (status) => {
  switch (status) {
    case 'initiated':
      return 'warning';
    case 'pending':
      return 'warning';
    case 'succeeded':
      return 'success';
    default:
      return 'error';
  }
};

function Orders(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const orders = useSelector((state) => state.orders.items);
  const isLoading = useSelector((state) => state.orders.loading);
  const [rows, setRows] = useState(orders);
  // For User Role
  const userRole = props.user.user_role;

  // Get All Packages
  React.useEffect(() => {
    getOrdersList();
    const type = userRole == 1 ? null : navigate('/dashboard/app');
    return () => {
      // setState({}); // To Avoid Memory Leak
      return type;
    };
  }, []);

  // Get All Packages
  React.useEffect(() => {
    setRows(orders);
  }, [orders]);

  const getOrdersList = () => {
    dispatch(allOrders());
  };

  // Search Functions
  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = orders.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  // Table Columns
  const columns = [
    {
      field: 'order_id',
      headerName: 'Order ID',
      width: 100,
      renderCell: (params) => (
        <Link to={`/dashboard/orders/order-details/${params.value}`} className="order-details-link">
          {`${params.value.slice(0, 6)}...`}
        </Link>
      )
    },
    {
      field: 'paid_amount',
      headerName: 'Amout Paid',
      width: 100,
      renderCell: (params) => <>{GBP.format(params.value)}</>
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      width: 130,
      renderCell: (rowData) => (
        <Label variant="ghost" color={getLabelColor(rowData.row.payment_status)}>
          {sentenceCase(rowData.row.payment_status ? rowData.row.payment_status : 'unavailable')}
        </Label>
      )
    },
    {
      field: 'stripe_risk_score',
      headerName: 'Risk Score',
      width: 130,
      renderCell: (rowData) => (
        <Label variant="ghost" color={rowData.row.stripe_risk_score > 40 ? 'error' : 'success'}>
          {sentenceCase(rowData.row.stripe_risk_score ? rowData.row.stripe_risk_score : 0)}
        </Label>
      )
    },
    {
      field: 'stripe_trx_id',
      headerName: 'Refunds',
      width: 100,
      renderCell: (params) => (
        <Link to={`/dashboard/refunds/${params.value}`} className="order-details-link">
          Refunds
        </Link>
      )
    },
    {
      field: 'order_status',
      headerName: 'Order Status',
      width: 120,
      renderCell: (rowData) => (
        <Label variant="ghost" color={getLabelColor(rowData.row.order_status)}>
          {sentenceCase(rowData.row.order_status ? rowData.row.order_status : 'unavailable')}
        </Label>
      )
    },
    {
      field: 'date',
      headerName: 'Order Date',
      width: 130,
      renderCell: (rowData) => new Date(rowData.row.date).toDateString()
    },
    {
      field: 'user_email',
      headerName: 'User Email',
      width: 200
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 160,
      renderCell: (rowData) => (
        <OrderActionButton
          id={rowData.row.id}
          orderId={rowData.row.order_id}
          amount={rowData.row.paid_amount}
        />
      )
    }
  ];

  return (
    <Page title={props.title ? 'Dashboard' : 'Dashboard: Orders'}>
      <Box p={props.title ? 0 : 2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            All Orders
          </Typography>
          <TextField
            variant="outlined"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              requestSearch(e.target.value);
            }}
            placeholder="Search..."
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" color="action" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: searchText ? 'visible' : 'hidden',
                    borderRadius: '57%',
                    paddingRight: '1px',
                    margin: '0',
                    fontSize: '1.25rem'
                  }}
                  onClick={() => {
                    setSearchText('');
                    setRows(orders);
                  }}
                >
                  <ClearIcon fontSize="small" color="action" />
                </IconButton>
              )
            }}
            sx={{
              width: { xs: 1, sm: 'auto' },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider'
              }
            }}
          />
        </Stack>
        {isLoading && <Loader />}
        {!isLoading && (
          <Stack sx={{ boxShadow: 3, borderRadius: 1, width: '100%' }}>
            <Typography variant="h6" gutterBottom pt={2} pl={2}>
              Data Table for Orders
            </Typography>
            <DataGrid
              disableSelectionOnClick
              disableColumnMenu
              // checkboxSelection
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row.id}
              rows={rows}
              columns={columns}
              autoHeight={true}
            />
          </Stack>
        )}
      </Box>
    </Page>
  );
}

export default withRole(Orders);
