import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
// material
import {
  Stack,
  Box,
  Typography,
  Grid,
  FormLabel,
  Button,
  TextField,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Delete } from '@mui/icons-material';
//
import { getBlogCategories } from 'src/api/api';
import BlogRTE from 'src/components/BlogRTE';
import { addBlog } from 'src/Redux/actions/blogsActions';
// ----------------------------------------------------------------------
export const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']; // Image Validation

export default function CreateBlogForm() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postThumb, setPostThumb] = useState('');
  const [blogCategories, setBlogCategories] = useState([]);
  // const userId = localStorage.getItem('userId');

  const fetchCategories = async () => {
    try {
      const resp = await getBlogCategories();
      if (resp.status === 200) {
        const { categories = [] } = resp.data;
        setBlogCategories(categories);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // fetch blog categories
    fetchCategories();
  }, []);

  // Validation Schema
  const BlogSchema = Yup.object().shape({
    title: Yup.string().required('Blog title is required'),
    slug: Yup.string(),
    body: Yup.string().required('Blog body is required'),
    post_image: Yup.mixed().required('Post Image is required')
  });

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (!validTypes.includes(file.type)) {
      toast.error('Please select an image, allowed file types are jpg, jpeg, png & webp.');
      return false;
    }
    setFieldValue('post_image', file);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        setPostThumb(fileReader.result);
      }
    };
    fileReader.readAsDataURL(file);
  };

  const getSlug = (text = '') => {
    return text
      .trim()
      .replace(/[^A-Za-z0-9-]+/g, '-')
      .toLowerCase();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={10} md={10} xs={10} sm={10}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                user_id: user?.id || 3,
                title: '',
                slug: '',
                category_id: 4,
                meta_title: '',
                meta_description: '',
                body: '',
                post_image: '',
                status: 1
              }}
              validationSchema={BlogSchema}
              onSubmit={(values, { setSubmitting }) => {
                const formData = new FormData();
                Object.keys(values).forEach((dataItem) => {
                  formData.append(dataItem, values[dataItem]);
                });
                dispatch(addBlog(formData, navigate, setSubmitting));
              }}
            >
              {({
                errors,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                isSubmitting
              }) => (
                <Form autoComplete="off">
                  {/* <code>{JSON.stringify(values, undefined, 2)}</code> */}
                  <Typography variant="h4" gutterBottom>
                    Add New Blog
                  </Typography>
                  <Stack spacing={3} mt={2}>
                    <TextField
                      type="text"
                      name="title"
                      label="Blog Title"
                      error={!!errors.title}
                      helperText={touched.title && errors.title && errors.title}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Box width={'100%'}>
                      <TextField
                        fullWidth
                        type="text"
                        name="slug"
                        label="Blog Slug"
                        value={values.slug}
                        onChange={handleChange}
                      />
                      {values.title && (
                        <Typography variant="body2">
                          Proposed slug: (Click to use or write your own): <br />
                          <Typography
                            variant="body2"
                            component={'span'}
                            color={'gray'}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setFieldValue('slug', getSlug(values.title))}
                          >
                            {getSlug(values.title)}
                          </Typography>
                        </Typography>
                      )}
                    </Box>
                    <FormControl fullWidth>
                      <InputLabel id="blog-category">Blog Category</InputLabel>
                      <Select
                        labelId="blog-category"
                        id="blog-category-select"
                        value={values.category_id}
                        label="Blog Category"
                        onChange={(e) => setFieldValue('category_id', e.target.value)}
                      >
                        {blogCategories.map((cate) => (
                          <MenuItem value={cate.id}>{cate.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      type="text"
                      id="meta_title"
                      name="meta_title"
                      label="Meta Title"
                      value={values.meta_title}
                      onChange={handleChange}
                    />
                    <TextField
                      type="text"
                      name="meta_description"
                      label="Meta Description"
                      value={values.meta_description}
                      onChange={handleChange}
                    />
                    <FormLabel id="body">Body</FormLabel>
                    <BlogRTE setFieldValue={setFieldValue} name="body" />
                    {errors.body && touched.body && (
                      <Typography color={'error'} variant="body2">
                        {errors.body}
                      </Typography>
                    )}
                    <FormLabel htmlFor="post_image">Post Image</FormLabel>
                    <TextField
                      id="post_image"
                      name="post_image"
                      type="file"
                      accept=".jpg, .jpeg, .png, .webp"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                    {postThumb && (
                      <Box>
                        <Typography variant="body2" gutterBottom>
                          Image Preview
                        </Typography>
                        <Box position={'relative'} width={200} height={200}>
                          <IconButton
                            aria-label="delete"
                            sx={{
                              position: 'absolute',
                              right: 0,
                              color: 'common.white'
                            }}
                            onClick={() => (setFieldValue('post_image', ''), setPostThumb(''))}
                          >
                            <Delete />
                          </IconButton>
                          <img
                            src={postThumb}
                            width={200}
                            height={200}
                            style={{
                              objectPosition: 'top left',
                              objectFit: 'contain'
                            }}
                            alt="blog post image"
                          />
                        </Box>
                      </Box>
                    )}
                    {errors.body && touched.body && (
                      <Typography color={'error'} variant="body2">
                        {errors.post_image}
                      </Typography>
                    )}
                    <FormLabel id="active">Status</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.status == 1}
                          onChange={() => setFieldValue('status', values.status == 1 ? 0 : 1)}
                        />
                      }
                      label={values.status == 1 ? 'Active' : 'Inactive'}
                    />
                  </Stack>
                  <Grid container>
                    <Grid item xs={5} sm={5} md={3} lg={2} mt={3}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={5} sm={5} md={3} lg={2} ml={3} mt={3}>
                      <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="error"
                        component={RouterLink}
                        disabled={isSubmitting}
                        to="/dashboard/blogs"
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
